import Screen from "../../components/common/screen";
import {FC, useEffect, useState} from "react";
import Course from "../../models/course";
import LoadingIndicator from "../../components/common/loading";
import {useHistory} from "react-router-dom";
import AdminAPI from "../../services/admin";
import {dateToDMY} from "../../services/utils";

const AdminCourseListScreen = () => {
    const history = useHistory()
    const [courses, setCourses] = useState<Course[]>([])
    const [loading, setLoading] = useState(true)

    const createCourse = () => {
        history.push('/course_editor/new')
    }

    // Load users when page first loads
    useEffect(() => {
        fetchCourses(1, setCourses)
            .then(() =>  setLoading(false))
            .catch(console.log)
    }, [])

    return <Screen title='Manage Courses' key='/manage-courses'>
        <h1>Manage Courses</h1>
        <div className="row">
            <p style={{flex: 1}}>Create a new course</p>
            <button className="course-button" onClick={createCourse}>Create</button>
        </div>
        <CourseList courses={courses} loading={loading} />
    </Screen>
}

const fetchCourses = async  (page: number, setCourses: (courses: Course[]) => void) => {
    let courses = (await AdminAPI.getCourseList()).map(data => new Course(data))
    setCourses(courses)
}

interface CourseListProps {
    courses: Course[]
    loading: boolean
}

const CourseList: FC<CourseListProps> = ({ courses, loading   }) => {
    if (loading) {
        return <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
            <LoadingIndicator />
        </div>
    } else {
        if (courses.length === 0) {
            return <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
                <p style={{color: 'grey'}}>No courses found</p>
            </div>
        } else {
            return <div className="user-list">
                {courses.map((course, i) => <CourseCard key={`${i} ${course.data.courseID}`} course={course} />)}
            </div>
        }
    }
}

interface CourseCardProps {
    course: Course
}
const CourseCard: FC<CourseCardProps> = ({ course }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/course_editor/${course.data.courseID}`)
    }

    return <div className="user-card" onClick={handleClick}>
        <div style={{display: "flex", flexDirection: 'row', padding: "5px 0"}}>
            <p className="user-card-type">{ course.data.category }</p>
            <p className="user-card-date">{ dateToDMY(course.data.dateModified) }</p>
        </div>
        <p className="user-card-name">{ course.data.title }</p>
        <p className="user-card-email">Sections: { course.data.sections.length }</p>
    </div>
}

export default AdminCourseListScreen