import {FC} from "react";
import {accessFromString} from "../../models/access";
import {capitalise} from "../../services/utils";

interface CourseProps {
    accessStr: string
    onDelete: (access: string)=>void
}

const AdminGroupCourseContentItem: FC<CourseProps> = ({ onDelete, accessStr }) => {
    const access = accessFromString(accessStr)

    if (access.category === 'group_assessment') {
        return <></>
    }

    return <div className='row' style={{borderTop: '1px solid #ccc', padding: '5px 10px'}}>
        <div style={{flex: 1}}>
            <small>{capitalise(access.category)}</small>
            <p>{access.detail1}</p>
        </div>
        <i className="material-icons" onClick={() => onDelete(accessStr)}>delete</i>
    </div>
}

export default AdminGroupCourseContentItem