import {FC} from "react";

interface Props {
    icon: string
    title: string
    description: string
    href: string
    onClick: (route: string) => void
    enabled?: boolean
    hint?: string
}

const HomeCard: FC<Props> = ({ icon, title, description, href, onClick, enabled= true, hint = '' }) => {
    const handleClick = () => {
        if (enabled) {
            onClick(href)
        }
    }

    return <div onClick={handleClick} className={enabled?"home-card":"home-card-disabled"}>
        <div className="icon">
            <i className='material-icons'>{ icon }</i>
        </div>
        <div style={{height: '50px'}} />
        <h3>{ title }</h3>
        <hr style={{margin: '10px 0'}} />
        <p>{ description }</p>
        <br />
        {hint !== ""? <small
            style={{
                textAlign: 'center', backgroundColor: '#e0e0e0', padding: '5px 10px',
                borderRadius: '10px', color: 'black'
            }}>
            {hint}
        </small>: <></>}
    </div>
}

export default HomeCard