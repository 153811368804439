import Screen from "../../components/common/screen";
import {FC, useEffect, useState} from "react";
import {CourseData, emptyCourseData, emptyQuestion, emptySection} from "../../models/course";
import EditCourseDetails from "../../components/adminCourseEditor/details";
import EditCourseSection from "../../components/adminCourseEditor/section";
import {useHistory} from "react-router-dom";
import AdminAPI from "../../services/admin";
import {useUser} from "../../models/user";
import LoadingIndicator from "../../components/common/loading";
import AptTickAPI from "../../services/api";
import {dateToDMY} from "../../services/utils";
import {UploadTask} from "firebase/storage";
import UploadProgressDialog from "../../components/adminCourseEditor/uploadProgress";
import {ConfirmationDialog} from "../../components/common/alert";

export const courseEditorFiles: File[] = []

interface ConfirmProps {
    show: boolean
    title: string
    message: string
    onConfirm: ()=>void
}

const AdminCourseEditorScreen = () => {
    const user = useUser()
    const history = useHistory()
    const [id, setID] = useState('new')
    const [isLoading, setLoading] = useState(true)
    const [course, setCourse] =  useState(emptyCourseData)
    const [uploads, setUploads] = useState<UploadTask[]>([])
    const [confirmDialogDetails, setConfirmDialog] = useState<ConfirmProps>({
        show: false,
        title: 'Confirm',
        message: 'confirm dialog',
        onConfirm: () => '',
    })

    useEffect(() => {
        const arr = history.location.pathname.split('/')
        const courseID = arr[arr.length-1]
        setID(courseID)
        if (courseID !== 'new') {
            AptTickAPI.getCourse(courseID).then(_course => {
                setCourse(_course.data)
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    const addSection = () => {
        const sections = course.sections
        sections.push({...emptySection, quiz: []})
        setCourse({...course, sections: sections})
    }

    const saveCourse = async () => {
        setLoading(true)
        if (id === 'new') {
            const newID = await AdminAPI.createCourse(user.data, course)
            setUploads(await AdminAPI.uploadFiles(`Courses/${newID}`, courseEditorFiles))
            setID(newID)
            history.replace(`/course_editor/${newID}`)
        } else {
            setUploads(await AdminAPI.uploadFiles(`Courses/${course.courseID}`, courseEditorFiles))
            await AdminAPI.saveCourse(user.data, course)
        }
        courseEditorFiles.splice(0, courseEditorFiles.length)
        setLoading(false)
    }

    const previewCourse = async () => {
        await saveCourse()
        history.push(`/course_preview/${id}`)
    }

    const confirmPublish = async () => {
        setConfirmDialog({
            show: true,
            title: course.status==='published'? 'Unpublish Bundle': 'Publish Bundle',
            message: course.status==='published'? 'Are you sure you want to unpublish this bundle?':
                'Are you sure you want to publish this group?',
            onConfirm: publishCourse,
        })
    }

    const publishCourse = async () => {
        const tempCourse = {...course, status: course.status==='published'? 'unpublished': 'published'}
        course.status = tempCourse.status
        // TODO: add validation
        setCourse({...tempCourse})
        await saveCourse()
    }

    const confirmDelete = async () => {
        setConfirmDialog({
            show: true,
            title: 'Delete Course',
            message: 'Are you sure you want to permanently delete this course?',
            onConfirm: deleteCourse,
        })
    }

    const deleteCourse = async () => {
        const tempCourse = {...course, status: 'deleted'}
        course.status = 'deleted'
        setCourse({...tempCourse})
        await saveCourse()
        history.goBack()
    }

    // TODO: Set up ctrl+s shortcut for saving
    return <Screen key='/course_editor' title='Edit Course'>
        <h2>{id==='new'?'Create':'Edit'} Course</h2>
        <p>Created on {dateToDMY(course.dateCreated)}</p>
        <br />
        <div className='row'>
            <button onClick={saveCourse} className='course-button'>Save</button>
            <span style={{width: '10px'}} />
            <button onClick={previewCourse} className='course-button'>Preview</button>
            <span style={{width: '10px'}} />
            <button onClick={confirmPublish} className='course-button'>{course.status==='published'?'Unpublish':'Publish'}</button>
            <span style={{width: '10px'}} />
            <button onClick={confirmDelete} className='course-button'>Delete</button>
        </div>
        {(isLoading || uploads.length>0)? <LoadingIndicator/>:
            <>
                <EditCourseDetails course={course} setCourse={setCourse} />
                <h2>Sections</h2>
                <p>Total Sections: {course.sections.length}</p>
                {course
                    .sections
                    .map((_, i) =>
                        <EditCourseSection key={`Section ${i}`} index={i} course={course} setCourse={setCourse} />
                    )
                }
                <br />
                <button onClick={addSection} style={{width: '80%'}} className='course-editor-button'>
                    Add Section
                </button>
            </>
        }
        {confirmDialogDetails.show ?
            <ConfirmationDialog
                title={confirmDialogDetails.title}
                message={confirmDialogDetails.message}
                onCancel={() => setConfirmDialog({...confirmDialogDetails, show: false})}
                onConfirm={confirmDialogDetails.onConfirm}
            />: <></>
        }
        {uploads.length>0?
            <UploadProgressDialog
                uploads={uploads}
                onComplete={() => setUploads([])}
                onError={console.log} />
            :<></>
        }
    </Screen>
}

export default AdminCourseEditorScreen