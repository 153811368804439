import {UserData, useUser} from "../../models/user";
import {FC} from "react";

interface ProfileProps {
    initials?: string
    name?: string
    email?: string
    topText?: string
    bottomText?: string
}

const UserProfile: FC<ProfileProps> = ({ initials, name, email, topText, bottomText }) => {
    const user = useUser()

    return <div className="profile-container">
        <div className="profile-icon">{initials ?? user.initials}</div>
        <div className="profile-text">
            <p>{topText}</p>
            <p className="profile-name"> {name ?? user.name} </p>
            <p className="profile-email"> {email ?? user.email} </p>
            <p>{bottomText}</p>
        </div>
    </div>
}

export default UserProfile