import {FC} from "react";
import {GroupData} from "../../models/group";

interface AssessmentProps {
    group: GroupData
    setField: (field: string, num: number)=>void
}

const AdminGroupAssessmentContentItem: FC<AssessmentProps> = ({ group, setField }) => {
    const increment = () => {
        setField('totalQuestionnaires', group.totalQuestionnaires+1)
    }

    const decrement = () => {
        if (group.totalQuestionnaires > 0) {
            setField('totalQuestionnaires', group.totalQuestionnaires-1)
        }
    }

    return <div className='row' style={{borderTop: '1px solid #ccc', padding: '5px 10px'}}>
        <div style={{flex: 1}}>
            <small>Assessment</small>
            <p>MECA</p>
        </div>
        <button className="course-button" onClick={decrement}>-</button>
        <p>{group.totalQuestionnaires}</p>
        <button className="course-button" onClick={increment}>+</button>
    </div>
}

export default AdminGroupAssessmentContentItem