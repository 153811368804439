import {CourseData, emptyQuestion} from "../../models/course";
import {FC, useEffect, useState} from "react";
import EditCourseQuestion from "./question";
import {courseEditorFiles} from "../../screens/admin/courseEditor";

interface SectionProps {
    index: number
    course: CourseData
    setCourse: (data: CourseData) => void
}

const EditCourseSection: FC<SectionProps> = ({ index, course, setCourse }) => {
    const section = course.sections[index]
    const [title, setTile] = useState(section.title)
    const [description, setDescription] = useState(section.description)
    const [firstSlide, setFirst] = useState(section.firstSlide)
    const [lastSlide, setLast] = useState(section.lastSlide)
    const [videoFile, setVideoFile] = useState<File|null|undefined>(course.sections[index].videoURL!==''?new File([], course.sections[index].videoURL):null)
    const [thumbFile, setThumbFile] = useState<File|null|undefined>(course.sections[index].thumbnailURL!==''?new File([], course.sections[index].thumbnailURL):null)

    useEffect(() => {
        const sections = course.sections
        const updatedSection = {...section, title, description, firstSlide, lastSlide}
        sections[index] = updatedSection
        setCourse({...course, sections})
    }, [title, description, firstSlide, lastSlide])

    const uploadVideo = () => {
        const picker = document.getElementById(`editor-video-upload-${index}`) as HTMLInputElement
        const selectedFile = picker.files?.item(0)
        if (selectedFile) {
            // Update file
            setVideoFile(selectedFile)
            courseEditorFiles.push(selectedFile)
            // Update course data
            const sections = course.sections
            sections[index].videoURL = selectedFile.name
            setCourse({...course, sections: sections})
        }
    }

    const uploadThumbnail = () => {
        const picker = document.getElementById(`editor-thumb-upload-${index}`) as HTMLInputElement
        const selectedFile = picker.files?.item(0)
        if (selectedFile) {
            // Update file
            setThumbFile(selectedFile)
            courseEditorFiles.push(selectedFile)
            // Update course data
            const sections = course.sections
            sections[index].thumbnailURL = selectedFile.name
            setCourse({...course, sections: sections})
        }
    }

    const removeSection = () => {
        const sections = course.sections
        sections.splice(index, 1)
        setCourse({...course, sections: sections})
    }

    const addQuestion = () => {
        const quiz = section.quiz
        quiz.push({...emptyQuestion, options: []})
        const sections = course.sections
        sections[index].quiz = quiz
        setCourse({...course, sections: sections})
    }

    return <div className='course-card course-editor-card'>
        <div className='row'>
            <h3 style={{flex: '1'}}>Section {index+1}</h3>
            <button onClick={removeSection} className='course-button'>Remove Section</button>
        </div>
        <hr />
        <p>Title</p>
        <input
            value={title}
            onChange={(e) => setTile(e.target.value)}
            className='course-editor-field'
            placeholder='Title'
        />
        <br />
        <p>Description</p>
        <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className='course-editor-field'
            placeholder='Description'
            style={{minHeight: '70px'}}
        />
        <br />
        <div className='row'>
            <p>Slides:</p>
            <input
                value={firstSlide}
                onChange={(e) => setFirst(parseInt(e.target.value))}
                className='course-editor-field'
                placeholder='--'
                inputMode={'numeric'}
                type={"number"}
            />
            <p>to</p>
            <input
                value={lastSlide}
                onChange={(e) => setLast(parseInt(e.target.value))}
                className='course-editor-field'
                placeholder='--'
                inputMode={'numeric'}
                type={"number"}
            />
        </div>
        <br />
        <p>Upload Video</p>
        <label>
            <div className='course-editor-upload-row'>
                <p className='course-editor-file-name'>{videoFile? videoFile.name: "No file selected"}</p>
                <div className='course-editor-upload'>
                    Upload
                    <i className='material-icons'>upload</i>
                </div>
            </div>
            <input
                type={'file'}
                id={`editor-video-upload-${index}`}
                onChange={uploadVideo}
                style={{display: 'none'}}
            />
        </label>
        {/*<br />*/}
        {/*<p>Upload Thumbnail Image</p>*/}
        {/*<label>*/}
        {/*    <div className='course-editor-upload-row'>*/}
        {/*        <p className='course-editor-file-name'>{thumbFile? thumbFile.name: "No file selected"}</p>*/}
        {/*        <div className='course-editor-upload'>*/}
        {/*            Upload*/}
        {/*            <i className='material-icons'>upload</i>*/}
        {/*        </div>*/}
        {/*    </div>*/}
        {/*    <input*/}
        {/*        type={'file'}*/}
        {/*        id={`editor-thumb-upload-${index}`}*/}
        {/*        onChange={uploadThumbnail}*/}
        {/*        style={{display: 'none'}}*/}
        {/*    />*/}
        {/*</label>*/}
        <br />
        <hr />
        <h4>Quiz</h4>
        <p>
            The quiz is made up of a collection of multiple choice questions. Add in all the options for the
            question then select the correct answer on the left.
        </p>
        <hr />
        {course
            .sections[index]
            .quiz
            .map((_, i) =>
                <EditCourseQuestion key={`Q ${index}-${i}`} questionIndex={i} sectionIndex={index} course={course} setCourse={setCourse} />
            )
        }
        <button onClick={addQuestion} className='course-editor-button'>Add Question</button>
    </div>
}

export default EditCourseSection