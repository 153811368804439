import Screen from "../components/common/screen";
import Course, {ContentBundle, CoursePersonalSummary, emptyBundle} from "../models/course";
import {FC, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import AptTickAPI from "../services/api";
import {accessFromString} from "../models/access";
import LoadingIndicator from "../components/common/loading";
import {useUser} from "../models/user";

const CourseBundleScreen = () => {
    // TODO: handle progress for sequential bundles
    const user = useUser()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [bundle, setBundle] =  useState<ContentBundle>(emptyBundle)
    const [courseSummaries, setSummaries] = useState<CoursePersonalSummary[]>([])

    useEffect(() => {
        const arr = history.location.pathname.split('/')
        const id = arr[arr.length-1]
        if (id) {
            AptTickAPI.getBundle(id).then(async _bundle => {
                setBundle(_bundle)
                setSummaries(await user.getPersonalCourseList())
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    const isUnlocked = (index: number) => {
        if (index === 0) {
            return true
        }

        // TODO: test
        if (bundle.isSequential) {
            const id = accessFromString(bundle.content[index-1]).reference
            const summary = courseSummaries.find(s => s.courseID === id)
            if (summary) {
                return summary.isComplete
            }
            return false
        }
        return true
    }

    return <Screen key='course-bundle' title="Courses">
        <h2>Courses</h2>
        <br />
        {loading? <LoadingIndicator/>:
            <div className="questionnaire-card">
                <p>Bundle</p>
                <h2>{bundle.title}</h2>
                <hr />
                <p>{bundle.description}</p>
                <br />
                {
                    bundle.content.map((accessStr, i) =>
                        <CourseBundleItem
                            accessStr={accessStr}
                            unlocked={isUnlocked(i)}
                        />)
                }
            </div>
        }
    </Screen>
}

interface ItemProps {
    accessStr: string
    unlocked: boolean
}

const CourseBundleItem: FC<ItemProps> = ({ accessStr, unlocked }) => {
    const history = useHistory()
    const access = accessFromString(accessStr)

    const handleGo = () => {
        history.push(`/course/${access.reference}`)
    }

    return <div className='row' style={{borderTop: '1px solid #ddd', padding: '10px 5px'}}>
        <i className='material-icons'>{unlocked? 'lock_open':'lock'}</i>
        <div style={{flex: '1', padding: '0 20px'}}>
            <p style={{color: unlocked? '#4caf50': 'red'}}>{unlocked? 'Unlocked': 'Locked'}</p>
            <h3>{access.detail1}</h3>
        </div>
        {unlocked? <button onClick={handleGo} className='course-button'>Go</button>: <></>}
    </div>
}

export default CourseBundleScreen