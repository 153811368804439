import {FC} from "react";
import {accessFromString} from "../../models/access";
import {useHistory} from "react-router-dom";
import {capitalise} from "../../services/utils";

interface ItemProps {
    accessStr: string
}

const GroupAccessItem: FC<ItemProps> = ({ accessStr }) => {
    const history = useHistory()
    const access = accessFromString(accessStr)
    const category = access.category
    const detail = category==='group_assessment'? access.reference: access.detail1

    const handleClick = () => {
        switch (access.category) {
            case "group_assessment":
                history.push('/')
                break;
            case "bundle":
            case "course":
                history.push('/courses')
                break
            default:
                history.push('/')
        }
    }

    return <div className='row' style={{borderBottom: '1px solid #ccc', padding: '5px 10px'}}>
        <div style={{flex: 1}}>
            <small>{capitalise(category.replace('_', ' '), true)}</small>
            <p>{detail}</p>
        </div>
        <button className="course-button" onClick={handleClick}>Go</button>
    </div>
}

export default GroupAccessItem