import * as logo from '../assets/images/icon3.jpg'
import {isEmpty} from "../services/utils";
import {useState} from "react";
import User from "../models/user";
import {authErrorHint} from "../services/auth";
import LoadingIndicator from "../components/common/loading";

interface Validation {
    email: string
    firstName: string
    lastName: string
    password1: string
    password2: string
}

interface Fields {
    email: string
    firstName: string
    lastName: string
    password1: string
    password2: string
}

const SignUpScreen = () => {
    const [showLoading, setLoading] = useState(false)
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [error, setError] = useState('')
    const [validator, setValidator] = useState<Validation>({email, firstName, lastName, password1, password2})

    const handleSubmit = async () => {
        const validator = validate({email, firstName, lastName, password1, password2})
        let error = ''
        Object.values(validator).reverse().forEach((item) => {
            if (item.length > 0) {
                error = item
            }
        })
        if (isEmpty(error)) {
            setLoading(true)
            try {
                await User.createAccount(company, email, firstName, lastName, password1)
                window.location.hash = '/home'
            } catch (e) {
                error = authErrorHint(e)
            }
            setLoading(false)
        }
        setValidator(validator)
        setError(error)
    }

    return <div className="login-card">
        <div className="login-form">
            <div className="row" style={{width: "100%"}}>
                <img className="login-logo" src={logo.default} alt='AptTick Logo' />
                <h1>Create Account</h1>
            </div>
            <div style={{height: '20px'}} />

            <p>Company Name (Optional)</p>
            <InputField
                title="Company Name (Optional)"
                value={company}
                setValue={setCompany} />
            <br /><br />
            <p>Email Address</p>
            <InputField
                title="Email"
                value={email}
                setValue={setEmail}
                errorMessage={validator.email} />
            <br /><br />
            <p>First Name</p>
            <InputField
                title="First Name"
                value={firstName}
                setValue={setFirstName}
                errorMessage={validator.firstName} />
            <br /><br />
            <p>Last Name</p>
            <InputField
                title="Last Name"
                value={lastName}
                setValue={setLastName}
                errorMessage={validator.lastName} />
            <br /><br />
            <p>Password</p>
            <InputField
                title="Password"
                value={password1}
                setValue={setPassword1}
                errorMessage={validator.password1} />
            <br /><br />
            <p>Re-enter Password</p>
            <InputField
                title="Re-enter Password"
                value={password2}
                setValue={setPassword2}
                errorMessage={validator.password2} />

            <a href="#login" className="forgot-password">Already have an account?</a>
            <div style={{height: '25px'}} />
            <p style={{color: "red", textAlign: "center"}}>{ error }</p>
            <div style={{height: '25px'}} />
            { showLoading? <LoadingIndicator />:
                <button onClick={handleSubmit} className="login-btn-primary">Create Account</button>
            }
        </div>
    </div>
}

interface InputFieldProps {
    title: string
    value: string
    setValue: (value: string) => void
    errorMessage?: string
}

function InputField({ title, errorMessage = "", value, setValue }: InputFieldProps) {
    // TODO: add view/hide for passwords
    // TODO: add error message
    return <input
        type={title.toLowerCase().includes("password")?"password":"text"}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={title}
        style={{border: errorMessage?'1px solid red': '1px solid #ddd'}}
    />
}


function validate(fields: Fields): Validation {
    const {email, firstName, lastName, password1, password2} = fields
    let emailError = ''
    let firstNameError = ''
    let lastNameError = ''
    let password1Error = ''
    let password2Error = ''

    // Validate email address
    if (isEmpty(email)) {
        emailError = "Enter your email address"
    } else if (!email.includes("@")) {
        emailError = "Enter a valid email address"
    }
    // Validate first name
    if (isEmpty(firstName)) {
        firstNameError = "Please enter your first name"
    }
    // Validate last name
    if (isEmpty(lastName)) {
        lastNameError = "Please enter your last name"
    }
    // Validate password1
    if (password1.length < 1) {
        password1Error = "Please enter a password"
    }
    // Validate password2
    if (password2.length < 1) {
        password2Error = "Please re-enter a password"
    } else if (password2 !== password1) {
        password2Error = "Passwords do not match"
    }

    return {
        email: emailError,
        firstName: firstNameError,
        lastName: lastNameError,
        password1: password1Error,
        password2: password2Error
    }
}

export default SignUpScreen