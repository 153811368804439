import {newQuestionnaire, Questionnaire} from "./questionnaire";
import Auth from "../services/auth";
import AptTickAPI from "../services/api";
import {capitalise, isEmpty} from "../services/utils";
import AccessControl, {accessFromString, AssessmentAccess, CourseAccess, GroupAccess} from "./access";
import {ReportData} from "./report";
import Course, {ContentBundle, CoursePersonalSummary, emptyBundle} from "./course";
import GroupAccount from "./group";

export interface UserData {
    userID: string
    firstName: string
    lastName: string
    email: string
    company: string
    accountType: string
    access: string[]
    created: Date
}

const defaultUser = {
    userID: "---",
    firstName: "Guest",
    lastName: "Demo",
    email: "email@example.com",
    company: "Individual",
    accountType: "None",
    access: [],
    created: new Date(),
}

export default class User {
    private accessList: AccessControl[] = []
    public questionnaires: Questionnaire[] = []

    constructor(public data: UserData = defaultUser) {}

    static Local(shouldRedirect: boolean = true): User {
        const data = sessionStorage.getItem("user")
        if (data) {
            return new User(JSON.parse(data))
        } else if (shouldRedirect) {
            window.location.replace("#/login")
        }
        return new User()
    }

    static async logIn(email: string, password: string) {
        const auth = await Auth.loginIn(email, password)

        const data = await AptTickAPI.getUser(auth.user.uid)
        const user = new User(data);

        await user.save()

        // Sync groups
        await GroupAccount.syncUserData(user)
        // Sync bundles
        await Course.syncBundles(user)
        // Sync courses
        await Course.syncCourses(user)
    }

    static async createAccount(
        company: string,
        email: string,
        firstName: string,
        lastName: string,
        password: string,
    ) {
        const auth = await Auth.createAccount(email, password)

        const user = new User()
        user.data.userID = auth.user.uid
        user.data.email = email
        user.data.company = isEmpty(company)? "Individual": company
        user.data.firstName = firstName
        user.data.lastName = lastName
        user.data.accountType = "User"

        await AptTickAPI.setUser(user.data)
        const questionnaireData = newQuestionnaire(user)
        await AptTickAPI.addQuestionnaire(questionnaireData)
        const questionnaireAccess = AssessmentAccess.newQuestionnaire(questionnaireData)
        user.data.access.push(questionnaireAccess.toString())

        await user.save(true)
    }

    static async resetPassword(email: string) {
        await Auth.resetPassword(email)
    }

    get name() {
        return `${capitalise(this.data.firstName[0])}. ${capitalise(this.data.lastName, true)}`
    }

    get initials() {
        return `${(this.data.firstName[0])}.${this.data.lastName[0]}.`.toUpperCase()
    }

    get email() {
        return this.data.email
    }

    get lastQuestionnaire() {
        if (this.questionnaires.length > 0) {
            return this.questionnaires[this.questionnaires.length-1]
        }
        // TODO: handle errors
        return new Questionnaire(newQuestionnaire(this))
    }

    get isAdmin(): boolean {
        return this.data.accountType === 'Admin'
    }

    signOut = async () => {
        await Auth.signOut()

        sessionStorage.clear()
        localStorage.clear()
    }

    isSignedIn = () => {
        const userData = sessionStorage.getItem("user")
        return !!userData;
    }

    save = async (syncOnline: boolean = false) => {
        sessionStorage.setItem("user", JSON.stringify(this.data))

        if (syncOnline) {
            await AptTickAPI.setUser(this.data)
        }
    }

    getAccessList = (shouldRefresh = false) =>  {
        if (shouldRefresh || this.accessList.length === 0) {
            this.accessList = this.data.access.map(access => accessFromString(access))
        }
        return this.accessList
    }

    getQuestionnaires = async (getAll: boolean = false) => {
        this.questionnaires = await AptTickAPI.getQuestionnaire(this.data, getAll)
    }

    completeQuestionnaire = (report: ReportData) => {
        this.getAccessList()
        AssessmentAccess.completeQuestionnaire(this.accessList, report, this.data)
    }

    getPersonalCourseList = async (): Promise<CoursePersonalSummary[]> => {
        return await AptTickAPI.getCoursePersonalSummaryList(this)
    }

    getBundleList = async (): Promise<ContentBundle[]> => {
        return await AptTickAPI.getBundleList(this)
    }

    get hasQuestionnaireAccess() {
        return AssessmentAccess.hasQuestionnaireAccess(this.getAccessList())
    }

    get hasReportAccess() {
        return AssessmentAccess.hasReportAccess(this.getAccessList())
    }

    hasCourseAccess = (courseID?: string): boolean => {
        return CourseAccess.hasCourseAccess(this.getAccessList(), courseID)
    }

    hasGroupAccess = (groupID: string): boolean => {
        return GroupAccess.hasGroupAccess(this.getAccessList(), groupID)
    }

}

export function useUser(shouldRedirect: boolean = true) {
    return User.Local(shouldRedirect)
}