import Screen from "../../components/common/screen";
import {useUser} from "../../models/user";
import {useHistory} from "react-router-dom";
import {FC, useEffect, useState} from "react";
import AptTickAPI from "../../services/api";
import AdminAPI from "../../services/admin";
import LoadingIndicator from "../../components/common/loading";
import {capitalise, dateToDMY} from "../../services/utils";
import CourseSelector from "../../components/adminGroupEditor/courseSelector";
import {ConfirmationDialog} from "../../components/common/alert";
import {ContentBundle, emptyBundle} from "../../models/course";
import {accessFromString} from "../../models/access";

interface ConfirmProps {
    show: boolean
    title: string
    message: string
    onConfirm: ()=>void
}

// TODO: add validation
const AdminBundleEditorScreen = () => {
    const user = useUser()
    const history = useHistory()
    const [id, setID] = useState('new')
    const [bundle, setBundle] =  useState(emptyBundle)
    const [isLoading, setLoading] = useState(true)
    const [showCourseSelector, setCourseSelector] = useState(false)
    const [confirmDialogDetails, setConfirmDialog] = useState<ConfirmProps>({
        show: false,
        title: 'Confirm',
        message: 'confirm dialog',
        onConfirm: () => '',
    })

    useEffect(() => {
        const arr = history.location.pathname.split('/')
        const bundleID = arr[arr.length-1]
        setID(bundleID)
        if (bundleID !== 'new') {
            AptTickAPI.getBundle(bundleID).then(_bundle => {
                setBundle(_bundle)
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    const setField = (field: string, value: any) => {
        const tempBundle = {...bundle} as Record<string, any>
        tempBundle[field] = value
        setBundle({...tempBundle as ContentBundle})
    }

    const handleCourseSelection = (newAccess: string[]) => {
        const accessList = [...newAccess]
        setField('content', [...accessList])
        setCourseSelector(false)
    }

    const deleteItem = (index: number) => {
        const accessList = [...bundle.content]
        accessList.splice(index, 1)
        setField('content', [...accessList])
    }

    const moveItemUp = (index: number) => {
        if (index > 0) {
            const accessList = [...bundle.content]
            accessList.splice(index, 1)
            accessList.splice(index-1, 0, bundle.content[index])
            setField('content', [...accessList])
        }
    }

    const moveItemDown = (index: number) => {
        if (index < bundle.content.length) {
            const accessList = [...bundle.content]
            accessList.splice(index, 1)
            accessList.splice(index+1, 0, bundle.content[index])
            setField('content', [...accessList])
        }
    }

    const saveBundle = async () => {
        setLoading(true)
        if (id === 'new') {
            const newID = await AdminAPI.createBundle(user.data, bundle)
            setID(newID)
            history.replace(`/bundle_editor/${newID}`)
        } else {
            await AdminAPI.saveBundle(user.data, bundle)
        }
        setLoading(false)
    }

    const orderContent = async () => {
        setConfirmDialog({...confirmDialogDetails, show: false})
        const tempBundle: ContentBundle = {...bundle, isSequential: !bundle.isSequential}
        bundle.isSequential = tempBundle.isSequential
        await saveBundle()
        setBundle({...tempBundle})
    }

    const confirmPublish = async () => {
        setConfirmDialog({
            show: true,
            title: bundle.status==='published'? 'Unpublish Bundle': 'Publish Bundle',
            message: bundle.status==='published'? 'Are you sure you want to unpublish this bundle?':
                'Are you sure you want to publish this bundle?',
            onConfirm: publishBundle,
        })
    }

    const publishBundle = async () => {
        setConfirmDialog({...confirmDialogDetails, show: false})
        const tempBundle = {...bundle, status: bundle.status==='published'? 'unpublished': 'published'}
        bundle.status = tempBundle.status
        // TODO: add validation
        setBundle({...tempBundle})
        await saveBundle()
    }

    const confirmDelete = async () => {
        setConfirmDialog({
            show: true,
            title: 'Delete Bundle',
            message: 'Are you sure you want to permanently delete this bundle?',
            onConfirm: deleteBundle,
        })
    }

    const deleteBundle = async () => {
        setConfirmDialog({...confirmDialogDetails, show: false})
        const tempBundle = {...bundle, status: 'deleted'}
        bundle.status = 'deleted'
        await saveBundle()
        setBundle({...tempBundle})
        history.goBack()
    }

    return <Screen key='/group_editor' title='Edit Group'>
        <h2>{id==='new'?'Create':'Edit'} Bundle</h2>
        <p>Created on {dateToDMY(bundle.dateCreated)}</p>
        <p>
            <span>{capitalise(bundle.status)} | </span>
            <span style={{color: bundle.isSequential?'yellowgreen': 'red'}}>{bundle.isSequential? 'Ordered': 'Unordered'}</span>
        </p>
        <br />
        <div className='row'>
            <button className='course-button' onClick={saveBundle}>Save</button>
            <span style={{width: '10px'}} />
            <button className='course-button' onClick={orderContent}>{bundle.isSequential? 'Unordered': 'Ordered'}</button>
            <span style={{width: '10px'}} />
            <button className='course-button' onClick={confirmPublish}>{bundle.status==='published'? 'Unpublish': 'Publish'}</button>
            <span style={{width: '10px'}} />
            <button className='course-button' onClick={confirmDelete}>Delete</button>
        </div>
        {isLoading? <LoadingIndicator/>:
            <div className="course-card course-editor-card">

                <h3>Details</h3>
                <br />
                <p>Title</p>
                <input
                    value={bundle.title}
                    onChange={(e) => setField('title', e.target.value)}
                    className='course-editor-field'
                    placeholder='Title'
                />
                <br />
                <p>Description</p>
                <textarea
                    value={bundle.description}
                    // TODO: handle this case better
                    onChange={(e) => setField('description', e.target.value)}
                    className='course-editor-field'
                    placeholder='Description'
                />

                <br />
                <hr />
                <br />
                <div className='row'>
                    <h3 style={{flex: 1}}>Content</h3>
                    <button className='course-button' onClick={() => setCourseSelector(true)}>Edit</button>
                </div>
                <br />
                {
                    // TODO: Add content editor
                    bundle.content.length===0?
                        <div className="row" style={{justifyContent: "center", alignItems: "center"}}>
                            <p style={{color: 'grey'}}>No content</p>
                        </div>:
                    bundle.content
                        .map((item, i) => <BundleEditorCourseItem
                            key={`${i} ${item}`}
                            index={i}
                            accessStr={item}
                            onDelete={deleteItem}
                            onMoveUp={moveItemUp}
                            onMoveDown={moveItemDown}
                        />)
                }
                <br />
            </div>
        }
        {showCourseSelector? <CourseSelector onClose={handleCourseSelection} initialState={bundle.content} includeBundles={false} />: <></>}
        {confirmDialogDetails.show ?
            <ConfirmationDialog
                title={confirmDialogDetails.title}
                message={confirmDialogDetails.message}
                onCancel={() => setConfirmDialog({...confirmDialogDetails, show: false})}
                onConfirm={confirmDialogDetails.onConfirm}
            />: <></>
        }
    </Screen>
}

interface ItemProps {
    index: number
    accessStr: string
    onDelete: (index: number)=>void
    onMoveUp: (oldIndex: number)=>void
    onMoveDown: (oldIndex: number)=>void
}

const BundleEditorCourseItem: FC<ItemProps> = ({ index, accessStr, onMoveUp, onMoveDown, onDelete }) => {
    const access = accessFromString(accessStr)

    if (access.category === 'group_assessment') {
        return <></>
    }

    return <div className='row' style={{borderTop: '1px solid #ccc', padding: '5px 10px'}}>
        <i onClick={() => onMoveUp(index)} className='material-icons'>arrow_upward</i>
        <i onClick={() => onMoveDown(index)} className='material-icons'>arrow_downward</i>
        <div style={{flex: 1, padding: '0 10px'}}>
            <small>{capitalise(access.category)}</small>
            <p style={{padding: '5px 0'}}>{access.detail1}</p>
        </div>
        <i className="material-icons" onClick={() => onDelete(index)}>delete</i>
    </div>
}

export default AdminBundleEditorScreen