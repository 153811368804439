import {FC, useState} from "react";
import LoadingIndicator from "./loading";

interface DialogProps {
    isLoading?: boolean
}

const CustomDialog: FC<DialogProps> = ({ isLoading = false, children }) => {
    return <div className="alert-overlay">
        <div className="alert-dialog">
            {isLoading? <LoadingIndicator />: children}
        </div>
    </div>
}

interface AlertProps {
    title?: string
    message: string
    onClose: ()=>void
    buttonText?: string
    isLoading?: boolean
}

export const AlertDialog: FC<AlertProps> = ({ message, onClose, title = 'Alert', buttonText='OK', isLoading = false }) => {
    return <CustomDialog isLoading={isLoading}>
        <h2>{title}</h2>
        <p>{message}</p>
        <br />
        <button className="course-button" onClick={onClose}>{buttonText}</button>
    </CustomDialog>
}

interface ConfirmProps {
    title?: string
    message: string
    cancelText?: string
    onCancel: ()=>void
    confirmText?: string
    onConfirm: ()=>void
    isLoading?: boolean
}

export const ConfirmationDialog: FC<ConfirmProps> = ({ message, onConfirm, onCancel, title="Confirmation", cancelText="Cancel", confirmText="Confirm", isLoading = false }) => {
    return <CustomDialog isLoading={isLoading}>
        <h2>{title}</h2>
        <p>{message}</p>
        <br />
        <div className="row" style={{justifyContent: "space-between"}}>
            <button className="course-button" onClick={onCancel}>{cancelText}</button>
            <button className="course-button" onClick={onConfirm}>{confirmText}</button>
        </div>
    </CustomDialog>
}

interface InputProps {
    title?: string
    message: string
    placeholder?: string
    cancelText?: string
    onCancel: ()=>void
    submitText?: string
    onSubmit: (value: string)=>void
}

export const InputDialog: FC<InputProps> = ({ message, onSubmit, onCancel, title="Input", cancelText="Cancel", submitText="Submit", placeholder='Type here' }) => {
    const [value, setValue] = useState('')

    return <CustomDialog>
        <h2 style={{padding: '0 5px'}}>{title}</h2>
        <p style={{padding: '0 10px'}}>{message}</p>
        <br />
        <input
            className='course-editor-field'
            placeholder={placeholder}
            value={value}
            onChange={e => setValue(e.target.value)}
            onKeyUp={e => {
                if (e.key === "Enter") {
                    onSubmit(value)
                }}
            }
            style={{margin: '0'}}
        />
        <br />
        <div className="row" style={{justifyContent: "space-between"}}>
            <button className="course-button" onClick={onCancel}>{cancelText}</button>
            <button className="course-button" onClick={() => onSubmit(value)}>{submitText}</button>
        </div>
    </CustomDialog>
}

export default CustomDialog