import {HashRouter, Route, Switch} from "react-router-dom";

import './styles/home.css'
import './styles/login.css'
import './styles/report.css'
import './styles/course.css'
import './styles/admin/users.css'
import './styles/admin/courses.css'

import HomeScreen from "./screens/home";
import LoginScreen from "./screens/login";
import SignUpScreen from "./screens/signup";
import QuestionnaireScreen from "./screens/questionnaire";
import ReportScreen from "./screens/report";
import ResetPasswordScreen from "./screens/resetPassword";
import CourseListScreen from "./screens/courseList";
import CourseScreen from "./screens/course";
import AdminHomeScreen from "./screens/admin/admin";
import AdminUserListScreen from "./screens/admin/userList";
import AdminCourseListScreen from "./screens/admin/courseList";
import AdminUserDetailsScreen from "./screens/admin/userDetails";
import AdminCourseEditorScreen from "./screens/admin/courseEditor";
import GroupInfoPage from "./screens/groupPage";
import GroupListScreen from "./screens/groupList";
import CoursePreviewScreen from "./screens/coursePreview";
import AdminGroupListScreen from "./screens/admin/groupList";
import AdminGroupEditorScreen from "./screens/admin/groupEditor";
import AdminBundleEditorScreen from "./screens/admin/bundleEditor";
import AdminBundleListScreen from "./screens/admin/bundleList";
import CourseBundleScreen from "./screens/courseBundle";
import {useEffect, useState} from "react";
import {useUser} from "./models/user";
import GroupAccount from "./models/group";
import LoadingIndicator from "./components/common/loading";
import Course from "./models/course";

function App() {
    const user = useUser(false)
    const [isLoading, setLoading] = useState(false)

    const syncData = async () => {
        // TODO: handle access control
        setLoading(true)
        // Sync groups
        user.data.access = await GroupAccount.syncUserData(user)
        await user.save()
        // Sync bundles
        await Course.syncBundles(user)
        // Sync courses
        await Course.syncCourses(user)
        setLoading(false)
    }

    useEffect(() => {
        if (user.isSignedIn()) {
            syncData().then(() => {
                setLoading(false)
            })
        }
    },[])

    if (isLoading) {
        return <div style={{width: '100vw', height: '100vh', display: 'flex'}}>
            <LoadingIndicator />
        </div>
    } else {
        return <HashRouter>
            <Switch>
                <Route path="/manage_bundles">
                    <AdminBundleListScreen />
                </Route>
                <Route path="/bundle_editor">
                    <AdminBundleEditorScreen />
                </Route>
                <Route path="/manage_groups">
                    <AdminGroupListScreen />
                </Route>
                <Route path="/group_editor">
                    <AdminGroupEditorScreen />
                </Route>
                <Route path="/manage_courses">
                    <AdminCourseListScreen />
                </Route>
                <Route path="/course_editor">
                    <AdminCourseEditorScreen />
                </Route>
                <Route path="/manage_users">
                    <AdminUserListScreen />
                </Route>
                <Route path="/user_details">
                    <AdminUserDetailsScreen />
                </Route>
                <Route path="/admin">
                    <AdminHomeScreen />
                </Route>
                <Route path="/groups" exact={true}>
                    <GroupListScreen />
                </Route>
                <Route path="/group">
                    <GroupInfoPage />
                </Route>
                <Route path="/course_bundle">
                    <CourseBundleScreen />
                </Route>
                <Route path="/courses" exact={true}>
                    <CourseListScreen />
                </Route>
                <Route path="/course_preview">
                    <CoursePreviewScreen />
                </Route>
                <Route path="/course">
                    <CourseScreen />
                </Route>
                <Route path="/report">
                    <ReportScreen />
                </Route>
                <Route path="/assessment">
                    <QuestionnaireScreen />
                </Route>
                <Route path="/reset_password">
                    <ResetPasswordScreen />
                </Route>
                <Route path="/login">
                    <LoginScreen />
                </Route>
                <Route path="/signup">
                    <SignUpScreen />
                </Route>
                <Route path="/">
                    <HomeScreen />
                </Route>
            </Switch>
        </HashRouter>
    }
}

export default App;
