import {getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, AuthError} from 'firebase/auth'
import fbApp from "./firebase";
import User from "../models/user";

const auth = getAuth(fbApp)

export default class Auth {
    static async loginIn(email: string, password: string) {
        return await signInWithEmailAndPassword(auth, email, password)
    }

    static async createAccount(email: string, password: string) {
        return await createUserWithEmailAndPassword(auth, email, password)
    }

    static async signOut() {
        await auth.signOut()
    }

    static async resetPassword(email: string) {
        await sendPasswordResetEmail(auth, email)
    }

    static validateUser(shouldRedirect: boolean = true): boolean {
        const user = User.Local()
        if (!user.isSignedIn()) {
            return false
        }
        return true
    }
}

export function authErrorHint(e: AuthError): string {
    switch (e.code) {
        case "permission-denied":
            return "The system is currently down. Please contact the system administrator"
        case "auth/invalid-email":
            return "Please enter a valid email address"
        case "auth/user-not-found":
            return "User not found. Create a new account to get started."
        case "auth/email-already-in-use":
            return "This email address has already been used"
        case "auth/wrong-password":
            return "Incorrect password"
        case "auth/network-request-failed":
            return "Please check your connection and try again"
        case "auth/weak-password":
            // TODO: should add stricter requirements?
            return "Your password should be at least 6 characters long"
    }
    console.log(e.code? e.code: e)
    return "Oops, something went wrong. Please contact support or try again later."
}