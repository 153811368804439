import Screen from "../components/common/screen";
import Course, {emptyCourseData} from "../models/course";
import CourseContentTabs from "../components/course/tabs";
import {FC, useEffect, useState} from "react";
import CoursePdfViewer from "../components/course/pdf";
import CourseVideoViewer from "../components/course/video";
import CourseInfoPage from "../components/course/info";
import CourseQuiz from "../components/course/quiz";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "../components/common/loading";
import {useUser} from "../models/user";

const CoursePreviewScreen = () => {
    const user = useUser()
    const [course, setCourse] = useState(new Course(emptyCourseData))
    const history = useHistory()

    const [pdfMode, setPdfMode] = useState(false)
    const [index, setIndex] = useState(0)
    const [showLoading, setLoading] = useState(true)

    useEffect(() => {
        const arr = history.location.pathname.split('/')
        const id = arr[arr.length-1]
        if (user.isAdmin) {
            Course.getCourse(id).then(_course => {
                setCourse(_course)
                setLoading(false)
            }).catch(e => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    return <Screen key='/course' title={course.data.title}>
        <CourseContentTabs pdfMode={pdfMode} setPdfMode={setPdfMode} />
        <br />
        <h2>Preview: {course.data.title}</h2>
        <br />
        {showLoading? <LoadingIndicator />:
            <CourseContent
                pdfMode={pdfMode}
                course={course}
                index={index}
                setIndex={setIndex}
            />
        }
    </Screen>
}

interface ContentProps {
    course: Course
    index: number
    setIndex: (i: number)=>void
    pdfMode: boolean
}

const CourseContent: FC<ContentProps> = ({ course, index, setIndex, pdfMode }) => {
    const showQuiz = (index%2 === 1)
    const sectionIndex = Math.floor(index/2)
    const section = course.data.sections[sectionIndex]

    const next = () => {
        course.nextSection(index, setIndex)
    }

    const prev = () => {
        course.prevSection(index, setIndex)
    }

    if (index < 0) {
        return <CourseInfoPage onClick={() => setIndex(0)} course={course.data} />
    }
    if (showQuiz) {
        return <CourseQuiz course={course} sectionIndex={sectionIndex} next={next} prev={prev} />
    }
    if (pdfMode) {
        return <CoursePdfViewer course={course} prev={prev} next={next} section={section} />
    } else {
        return <CourseVideoViewer course={course.data} section={section} next={next} prev={prev} />
    }
}

export default CoursePreviewScreen