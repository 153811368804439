import User, {UserData} from "../models/user";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    deleteDoc,
    getFirestore,
    limit,
    orderBy,
    query,
    setDoc,
    Timestamp, where, DocumentData, startAt
} from 'firebase/firestore'
import {getBlob, getBytes, getDownloadURL, getStorage, ref as fileRef, } from 'firebase/storage'
import fbApp from "./firebase";
import {Questionnaire, QuestionnaireData} from "../models/questionnaire";
import Report, {ReportData} from "../models/report";
import Course, {ContentBundle, CourseData, CoursePersonalSummary} from "../models/course";
import GroupAccount, {
    demoGroupAccount,
    GroupAssessmentRelease,
    GroupData,
    GroupInfoSummary,
    GroupMember
} from "../models/group";
import {GroupAccess} from "../models/access";

const db = getFirestore(fbApp)
const storage = getStorage(fbApp)

function docToCourseData(doc: DocumentData) {

}

function docToGroupData(doc: DocumentData): GroupData {
    const group = doc as GroupData
    group.terminationDate = (doc['terminationDate'] as Timestamp).toDate()
    group.dateCreated = (doc['dateCreated'] as Timestamp).toDate()
    return group
}

// TODO: add error handling
export default class AptTickAPI {
    // TODO: update security rules

    // =======================
    // Database Access Methods
    // =======================

    // User data

    static async setUser(user: UserData) {
        const ref = doc(db, `Users/${user.userID}`)
        await setDoc(ref,  user)
    }

    static async getUser(userID: string): Promise<UserData> {
        const ref = doc(db, `Users/${userID}`)
        const snap = await getDoc(ref)
        return snap.data() as UserData
    }

    // Questionnaire data

    static async addQuestionnaire(data: QuestionnaireData) {
        const ref = await addDoc(collection(db, `Users/${data.userID}/Questionnaires`), data)
        data.questionnaireID = ref.id
        await AptTickAPI.updateQuestionnaire(data)
    }

    static async getQuestionnaire(user: UserData, getAll: boolean = false): Promise<Questionnaire[]> {
        const ref = collection(db, `Users/${user.userID}/Questionnaires`)
        const q = query(ref, orderBy('dateCreated'), limit(getAll? 3: 1))
        const data = await getDocs(q)
        return data.docs.map(doc => new Questionnaire(doc.data() as QuestionnaireData))
    }

    static async updateQuestionnaire(data: QuestionnaireData) {
        const ref = doc(db, `Users/${data.userID}/Questionnaires/${data.questionnaireID}`)
        await setDoc(ref, data)
    }

    static async submitQuestionnaire(user: User, data: QuestionnaireData) {
        // Save questionnaire
        const newQ: QuestionnaireData = {...data, isComplete: true, dateCompleted: new Date()}
        await this.updateQuestionnaire(newQ)
        // Generate and save report
        const report = Report.generate(data)
        await addDoc(collection(db, `Users/${data.userID}/Reports`), report)
        // Update user's access from questionnaire to report
        user.completeQuestionnaire(report)
        await user.save(true)
    }

    // Report data

    static async getReport(user: UserData): Promise<ReportData> {
        const ref = collection(db, `Users/${user.userID}/Reports`)
        const q = query(ref, orderBy('dateCreated'), limit(1))
        const data = await getDocs(q)
        return data.docs.map(doc => doc.data() as ReportData)[0]
    }

    // Course data

    static async getCoursePersonalSummaryList(user: User): Promise<CoursePersonalSummary[]> {
        // Get all personal summaries in DB
        const ref = collection(db, `Users/${user.data.userID}/CourseSummaries`)
        const q = query(ref)
        let data = await getDocs(q)
        const onlineSummaries = data.docs.map(doc => doc.data() as CoursePersonalSummary)
        // Check if any courses with access are missing summaries and create them, add all summaries
        // with access to currentSummaries
        const currentSummaries: CoursePersonalSummary[] = []
        for (const access of user.getAccessList()) {
            if (access.category === 'course') {
                let summary = onlineSummaries.find(entry => (entry.courseID === access.reference))
                if (summary) {
                    currentSummaries.push(summary)
                } else {
                    const course = await AptTickAPI.getCourse(access.reference)
                    summary = await course.getSummary()
                    await AptTickAPI.saveCoursePersonalSummary(user.data.userID, summary)
                    currentSummaries.push(summary)
                }
            }
        }
        return currentSummaries
    }

    static async saveCoursePersonalSummary(userID: string, summary: CoursePersonalSummary) {
        await setDoc(doc(db, `Users/${userID}/CourseSummaries/${summary.courseID}`), summary)
    }

    static async getCourse(courseID: string): Promise<Course> {
        const ref = doc(db, `Courses/${courseID}`)
        const snap = await getDoc(ref)
        const course = snap.data() as CourseData
        course.dateModified = (snap.data()!!['dateModified'] as Timestamp).toDate()
        course.dateCreated = (snap.data()!!['dateCreated'] as Timestamp).toDate()
        return new Course(course)
    }

    static async getCourseSummary(user: UserData, course: Course): Promise<CoursePersonalSummary> {
        const ref = doc(db, `Users/${user.userID}/CourseSummaries/${course.data.courseID}`)
        const snap = await getDoc(ref)
        if (snap.exists()) {
            return snap.data() as CoursePersonalSummary
        } else {
            return course.getSummary(false)
        }
    }

    // Bundle data

    static async getBundle(bundleID: string): Promise<ContentBundle> {
        const ref = doc(db, `Bundles/${bundleID}`)
        const snap = await getDoc(ref)
        const bundle = snap.data() as ContentBundle
        bundle.dateModified = (snap.data()!!['dateModified'] as Timestamp).toDate()
        bundle.dateCreated = (snap.data()!!['dateCreated'] as Timestamp).toDate()
        return bundle
    }

    static async getBundleList(user: User): Promise<ContentBundle[]> {
        // Get all personal summaries in DB
        const ref = collection(db, `Users/${user.data.userID}/AvailableBundles`)
        const q = query(ref)
        let data = await getDocs(q)
        const onlineBundles = data.docs.map(doc => doc.data() as ContentBundle)
        // Check if any bundles with access are missing from user data and create them
        const currentBundles: ContentBundle[] = []
        for (const access of user.getAccessList()) {
            if (access.category === 'bundle') {
                let bundle = onlineBundles.find(entry => (entry.bundleID === access.reference))
                if (bundle) {
                    currentBundles.push(bundle)
                } else {
                    bundle = await AptTickAPI.getBundle(access.reference)
                    await AptTickAPI.savePersonalBundle(user, bundle)
                    currentBundles.push(bundle)
                }
            }
        }
        return currentBundles
    }

    static async savePersonalBundle(user: User, bundle: ContentBundle) {
        await setDoc(doc(db, `Users/${user.data.userID}/AvailableBundles/${bundle.bundleID}`), bundle)
    }

    // Group data

    static async getGroupList(user: User): Promise<GroupInfoSummary[]> {
        // TODO: validate that groups are enabled and before termination date
        const q = query(collection(db, `Users/${user.data.userID}/GroupInfo`))
        let data = await getDocs(q)
        const groups = data.docs.map(doc => doc.data() as GroupInfoSummary)
        const accessList = user.getAccessList().filter(access => access.category === 'group').map(access => access.reference)
        return groups.filter(group => accessList.includes(group.groupID))
    }

    static async getGroupData(groupID: string): Promise<GroupData> {
        const ref = doc(db, `Groups/${groupID}`)
        const snap = await getDoc(ref)
        return docToGroupData(snap.data()!!)
    }

    static async joinGroup(user: UserData, groupCode: string): Promise<GroupData> {
        // Get group data
        const ref = collection(db,'Groups')
        const q = query(ref, where('groupCode', '==', groupCode))
        const data = await getDocs(q)
        if (data.size !== 1) {
            throw 'Invalid group code'
        }
        const group = docToGroupData(data.docs[0].data())
        // Validation logic
        if (!(group.numMembers < group.maxMembers)) {
            throw `This group has already reached it's maximum capacity`
        } else if (!group.enabled) {
            throw `This group is currently inactive`
        } else if (group.terminationDate.valueOf() < new Date().valueOf()) {
            throw  `This group code has expired`
        }
        // Increase member count
        group.numMembers += 1
        await setDoc(doc(db, `Groups/${group.groupID}`),  group)
        // Add user to group member list
        const member: GroupMember = {
            userID: user.userID,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            membershipStatus: 'member',
            dateJoined: new Date(),
        }
        await setDoc(doc(db, `Groups/${group.groupID}/GroupMembers/${member.userID}`), member)
        // Add group info summary to user's data
        await AptTickAPI.saveGroupSummary(user, group)
        // Update user's access control
        user.access.push(GroupAccess.newGroup(group).toString())
        group.access.forEach(accessStr => {
            if(!accessStr.startsWith('group_assessment') && !user.access.includes(accessStr)) {
                user.access.push(accessStr)
            }
        })
        await new User(user).save(true)
        return group
    }

    static async saveGroupSummary(user: UserData, group: GroupData, info?: GroupInfoSummary) {
        const groupInfo: GroupInfoSummary = info? {...info,
            title: group.title,
            description: group.description,
            access: group.access,
        }: {
            groupID: group.groupID,
            title: group.title,
            description: group.description,
            access: group.access,
            available: true,
            dateJoined: new Date(),
        }
        await setDoc(doc(db, `Users/${user.userID}/GroupInfo/${group.groupID}`), groupInfo)
    }

    static async leaveGroup(userID: string, group: GroupData) {
        // TODO: remove group content from user access
        // Update user's access control
        const user = await AptTickAPI.getUser(userID)
        const accessList = user.access.filter(access => access!==GroupAccess.newGroup(group).toString())
        user.access = accessList
        await AptTickAPI.setUser({...user, access: accessList})
        // TODO: remove access to all group content

        // Decrease members and update admin list
        const updatedGroup: GroupData = {...group,
            numMembers: group.numMembers-1,
            admins: group.admins.filter(admin => admin!==userID)
        }
        await setDoc(doc(db, `Groups/${group.groupID}`),  updatedGroup)
        // Delete user from members list
        await deleteDoc(doc(db, `Groups/${group.groupID}/GroupMembers/${userID}`))
        return user
    }

    static async updateGroupInfo(group: GroupData) {
        // TODO: sync user's group info
        await setDoc(doc(db, `Groups/${group.groupID}`), group)
    }

    static async releaseQuestionnaire(user: UserData, group: GroupData) {
        // TODO: test
        // add questionnaire to release list
        const releaseData: GroupAssessmentRelease = {
            userID: user.userID,
            assessmentType: 'MECA',
            date: new Date(),
        }
        await addDoc(collection(db, `Groups/${group.groupID}/GroupAssessments`), releaseData)
        // decrease available questionnaires
        group.availableQuestionnaires -= 1
        await this.updateGroupInfo(group)
    }

    static async getGroupMembers(groupID: string, offset: number, batchSize: number): Promise<GroupMember[]> {
        const q = query(
            collection(db, `Groups/${groupID}/GroupMembers`),
            // TODO: limit to admins and members
            orderBy('email'),
            startAt(offset),
            limit(batchSize),
        )
        let data = await getDocs(q)
        return data.docs.map(doc => {
            const member = doc.data() as GroupMember
            member.dateJoined = (doc.data()['dateJoined'] as Timestamp).toDate()
            return member
        })
    }

    static async makeGroupMemberAdmin(group: GroupData, member: GroupMember): Promise<GroupMember> {
        const isAdmin = (member.membershipStatus === 'admin')
        // Update group admin list
        const updatedGroup: GroupData = {...group, admins: [...group.admins]}
        if (isAdmin) {
            const admins = [...group.admins].filter(admin => admin!==member.userID)
            updatedGroup.admins = admins
        } else {
            updatedGroup.admins.push(member.userID)
        }
        await setDoc(doc(db, `Groups/${group.groupID}`),  updatedGroup)
        // Update member's membership status
        const updatedMember: GroupMember = {...member, membershipStatus: isAdmin? 'member': 'admin'}
        await setDoc(doc(db, `Groups/${group.groupID}/GroupMembers/${member.userID}`),  updatedMember)

        return updatedMember
    }

    // ***********

    // ======================
    // Storage Access Methods
    // ======================

    static async getFileURL(path: string): Promise<String> {
        const ref = fileRef(storage, path)
        return await getDownloadURL(ref)
    }

    static async getFileBytes(path: string) {
        const ref = fileRef(storage, path)
        return await getBytes(ref)
    }

    static async getFileBlob(path: string) {
        const ref = fileRef(storage, path)
        return await getBlob(ref)
    }
}