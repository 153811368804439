import {FC} from "react";

function goBack() {
    window.history.back()
}

interface Props {
    onClick?: () => void,
}

const BackButton: FC<Props> = ({ onClick = goBack }) => {
    return <button className="back-button" onClick={onClick}>
        <i className="material-icons">arrow_back</i>
    </button>
}

export default BackButton