import Screen from "../components/common/screen";
import GroupAccount, {GroupData, GroupInfoSummary} from "../models/group";
import {FC, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "../components/common/loading";
import {useUser} from "../models/user";
import {AlertDialog, InputDialog} from "../components/common/alert";

const GroupListScreen = () => {
    const user = useUser()
    const history = useHistory()
    const [groups, setGroups] = useState<GroupInfoSummary[]>([])
    const [showLoading, setLoading] = useState(true)
    const [showJoinDialog, setJoinDialog] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertTitle, setAlertTitle] = useState('Error')
    const [alertMessage, setAlertMessage] = useState('Invalid group code')

    useEffect(() => {
        GroupAccount.getGroupList(user)
            .then(list => {
                setGroups(list)
                setLoading(false)
            }).catch(e => {
                setLoading(false)
            })
    }, [])

    const joinGroup = async (groupCode: string) => {
        setJoinDialog(false)
        setLoading(true)
        try {
            const group = await GroupAccount.joinGroup(user.data, groupCode)
            setAlertTitle('Success')
            setAlertMessage(`You have successfully joined ${group.title}`)
            setShowAlert(true)
            history.push(`/group/${group.groupID}`)
        } catch (e) {
            setAlertTitle('Error')
            setAlertMessage(`${e}`)
            setShowAlert(true)
        }
        setLoading(false)
    }

    return <Screen key="/groups" title="Groups">
        <h2>Groups</h2>
        <div className="row">
            <p style={{flex: 1}}>Join a new group</p>
            <button className="course-button" onClick={() => setJoinDialog(true)}>Join</button>
        </div>
        <br />
        {showLoading? <LoadingIndicator />:
            groups.length !== 0 ? groups.map(group => <GroupListCard group={group} key={group.groupID}/>):
                <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
                    <p style={{color: 'grey'}}>No groups found</p>
                </div>
        }
        {showJoinDialog?
            <InputDialog
                title="Join Group"
                message="Enter the group code you would like to join"
                placeholder="Code"
                onCancel={() => setJoinDialog(false)}
                submitText="Join"
                onSubmit={joinGroup}
            />: <></>
        }
        {showAlert ?
            <AlertDialog
                title={alertTitle}
                message={alertMessage}
                onClose={() => setShowAlert(false)}
            /> : <></>
        }
    </Screen>
}

interface CardProps {
    group: GroupInfoSummary
}

const GroupListCard: FC<CardProps> = ({ group }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/group/${group.groupID}`)
    }

    return <div className="user-card row" onClick={handleClick}>
        <div style={{flex: 1}}>
            <h3>{group.title}</h3>
            <p>{group.description}</p>
        </div>
        <i className="material-icons">chevron_right</i>
    </div>
}



export default GroupListScreen