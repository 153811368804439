import GroupAccount, {GroupMember} from "../../models/group";
import {FC, useEffect, useState} from "react";
import LoadingIndicator from "../common/loading";
import UserProfile from "../common/userProfile";
import {ConfirmationDialog} from "../common/alert";
import {useHistory} from "react-router-dom";
import {capitalise, dateToDMY} from "../../services/utils";
import AptTickAPI from "../../services/api";
import {useUser} from "../../models/user";

interface ListProps {
    group: GroupAccount
}

interface ConfirmDetails {
    loading: boolean
    show: boolean
    title: string
    message: string
    confirmText: string
    onConfirmed: ()=>void
}

const GroupMemberList: FC<ListProps> = ({ group }) => {
    const user = useUser()
    const  [showLoading, setLoading] = useState(true)
    const [members, setMembers] = useState<GroupMember[]>([])
    const [selectedMember, setSelectedMember] = useState(members[0])
    const [confirmationDetails, setConfirmationDetails] = useState<ConfirmDetails>({
        loading: false,
        show: false,
        title: 'Confirm',
        message: 'Message',
        confirmText: 'Confirm',
        onConfirmed: () => new Promise(() => ''),
    })

    useEffect(() => {
        group.getMembers()
            .then(users => {
                setMembers(users)
                setLoading(false)
            })
    }, [])

    const confirmDelete = async (member: GroupMember) => {
        setSelectedMember(member)
        setConfirmationDetails({
            loading: false,
            show: true,
            title: 'Remove Member',
            message: `Are you sure you want to remove ${capitalise(member.firstName, true)} ${capitalise(member.lastName, true)} from the group?`,
            confirmText: 'Remove',
            onConfirmed: () => handleDelete(member),
        })
    }

    const confirmAdmin = async (member: GroupMember) => {
        setSelectedMember(member)
        const isAdmin = (member.membershipStatus === 'admin')
        setConfirmationDetails({
            loading: false,
            show: true,
            title: isAdmin? 'Remove Admin': 'Make Admin',
            message:  `${isAdmin?'Remove':'Add'} ${capitalise(member.firstName, true)} ${capitalise(member.lastName, true)} as group admin.`,
            confirmText: 'Confirm',
            onConfirmed: () => handleMakeAdmin(member),
        })
    }

    const handleDelete = async (member: GroupMember) => {
        setConfirmationDetails({...confirmationDetails, show: true, loading: true})
        const newMembers = [...members]
        const index = newMembers.findIndex(m => (m.userID === member.userID))
        newMembers.splice(index, 1)
        setMembers(newMembers)
        await group.deleteMember(member.userID)
        setConfirmationDetails({...confirmationDetails, show: false})
    }

    const handleMakeAdmin = async (member: GroupMember) => {
        setConfirmationDetails({...confirmationDetails, show: true, loading: true})
        const newMembers = [...members]
        const index = newMembers.findIndex(m => (m.userID === member.userID))
        const updatedMember = await group.updateAdmin(member)
        newMembers[index] = updatedMember
        setMembers(newMembers)
        setConfirmationDetails({...confirmationDetails, show: false})
    }

    return <div>
        {showLoading? <LoadingIndicator />:
            <>
                <h3>Members</h3>
                {members.length!==0?
                    members.map(member => <MemberCard key={member.userID} onRequestDelete={confirmDelete} onRequestAdmin={confirmAdmin} member={member} isAdmin={user.isAdmin} />):
                    <p>No members found</p>
                }
            </>
        }
        {confirmationDetails.show ?
            <ConfirmationDialog
                isLoading={confirmationDetails.loading}
                title={confirmationDetails.title}
                message={confirmationDetails.message}
                onCancel={() => setConfirmationDetails({...confirmationDetails, show: false})}
                confirmText={confirmationDetails.confirmText}
                onConfirm={confirmationDetails.onConfirmed}
            /> : <></>
        }
    </div>
}

interface MemberCardProps {
    isAdmin: boolean
    member: GroupMember
    onRequestDelete: (member: GroupMember)=>void
    onRequestAdmin: (member: GroupMember)=>void
}

const MemberCard: FC<MemberCardProps> = ({ member, onRequestDelete, onRequestAdmin, isAdmin }) => {
    const history = useHistory()

    const viewReport = () => {
        history.push(`/report/${member.userID}`)
    }

    return <div className="user-card">
        <div className="row">
            <UserProfile
                initials={`${capitalise(member.firstName[0])}.${capitalise(member.lastName[0])}.`.toUpperCase()}
                name={capitalise(`${member.firstName} ${member.lastName}`, true)}
                email={member.email}
                bottomText={`${capitalise(member.membershipStatus)} | Joined ${dateToDMY(member.dateJoined)}`}
            />
            {isAdmin? <i className="material-icons" onClick={() => onRequestAdmin(member)}>manage_accounts</i>: <></>}
            <i className="material-icons" onClick={viewReport}>assessment</i>
            <i className="material-icons" onClick={() => onRequestDelete(member)}>delete</i>
        </div>
    </div>
}



export default GroupMemberList