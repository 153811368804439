import {BundleVersionHistory, ContentBundle, CourseData, CourseVersionHistory, emptyCourseData} from "../models/course";
import {UserData} from "../models/user";
import {getFirestore, query, getDocs, collection, where, addDoc, doc, getDoc, setDoc, Timestamp} from "firebase/firestore";
import fbApp from "./firebase";
import {getStorage, uploadBytesResumable, UploadTask, ref as fileRef} from "firebase/storage";
import {demoGroupAccount, GroupData, GroupVersionHistory} from "../models/group";

const db = getFirestore(fbApp)
const storage = getStorage(fbApp)

export default class AdminAPI {
    // Users

    static async getUserList(): Promise<UserData[]> {
        // TODO: implement
        return []
    }

    // Courses

    static async getCourseList(): Promise<CourseData[]> {
        const ref = collection(db,'Courses')
        const q = query(ref, where('status', '!=', 'deleted'))
        const data = await getDocs(q)
        return data.docs.map(doc => {
            const course = doc.data() as CourseData
            course.dateModified = (doc.data()['dateModified'] as Timestamp).toDate()
            course.dateCreated = (doc.data()['dateCreated'] as Timestamp).toDate()
            return course
        })
    }

    static async createCourse(user: UserData, course: CourseData): Promise<string> {
        const ref = await addDoc(collection(db, `Courses`), course)
        course.courseID = ref.id
        await AdminAPI.saveCourse(user, course)
        return ref.id
    }

    static async saveCourse(user: UserData, course: CourseData) {
        const ref = doc(db, `Courses/${course.courseID}`)
        await setDoc(ref, course)
        const history: CourseVersionHistory = {
            userID: user.userID,
            email: user.email,
            data: course,
            dateModified: new Date()
        }
        await addDoc(collection(db, `Courses/${course.courseID}/History`), history)
    }

    // Bundles

    static async getBundleList(): Promise<ContentBundle[]> {
        const ref = collection(db,'Bundles')
        const q = query(ref, where('status', '!=', 'deleted'))
        const data = await getDocs(q)
        return data.docs.map(doc => {
            const bundle = doc.data() as ContentBundle
            bundle.dateModified = (doc.data()['dateModified'] as Timestamp).toDate()
            bundle.dateCreated = (doc.data()['dateCreated'] as Timestamp).toDate()
            return bundle
        })
    }

    static async createBundle(user: UserData, bundle: ContentBundle): Promise<string> {
        const ref = await addDoc(collection(db, `Bundles`), bundle)
        bundle.bundleID = ref.id
        await AdminAPI.saveBundle(user, bundle)
        return ref.id
    }

    static async saveBundle(user: UserData, bundle: ContentBundle) {
        // TODO: implement
        const ref = doc(db, `Bundles/${bundle.bundleID}`)
        await setDoc(ref, bundle)
        const history: BundleVersionHistory = {
            userID: user.userID,
            email: user.email,
            data: bundle,
            dateModified: new Date()
        }
        await addDoc(collection(db, `Bundles/${bundle.bundleID}/History`), history)
    }

    // Groups

    static async getGroupList(): Promise<GroupData[]> {
        const ref = collection(db,'Groups')
        const q = query(ref, where('error', "!=", 'deleted'))
        const data = await getDocs(q)
        return data.docs.map(snap => {
            const group = snap.data() as GroupData
            group.terminationDate = (snap.data()!!['terminationDate'] as Timestamp).toDate()
            group.dateCreated = (snap.data()!!['dateCreated'] as Timestamp).toDate()
            return group
        })
    }

    static async createGroup(user: UserData, group: GroupData) {
        const ref = await addDoc(collection(db, `Groups`), group)
        group.groupID = ref.id
        await AdminAPI.saveGroup(user, group)
        return ref.id
    }

    static async saveGroup(user: UserData, group: GroupData) {
        const ref = doc(db, `Groups/${group.groupID}`)
        await setDoc(ref, group)
        const history: GroupVersionHistory = {
            userID: user.userID,
            email: user.email,
            data: group,
            dateModified: new Date()
        }
        await addDoc(collection(db, `Groups/${group.groupID}/History`), history)
    }

    // Storage

    static async uploadFiles(directory: string, files: File[]): Promise<UploadTask[]> {
        // TODO: handle large uploads
        const uploads: UploadTask[] = []
        for (const file of files) {
            const ref = fileRef(storage, `${directory}/${file.name}`)
            uploads.push(uploadBytesResumable(ref, await file.arrayBuffer(), {cacheControl: 'public,max-age=600000'}))
        }
        return uploads
    }
}