import Screen from "../../components/common/screen";
import {ContentBundle} from "../../models/course";
import {FC, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {dateToDMY} from "../../services/utils";
import AdminAPI from "../../services/admin";
import LoadingIndicator from "../../components/common/loading";

const AdminBundleListScreen = () => {
    const history = useHistory()
    const [bundles, setBundles] = useState<ContentBundle[]>([])
    const [loading, setLoading] = useState(true)

    const createCourse = () => {
        history.push('/bundle_editor/new')
    }

    // Load users when page first loads
    useEffect(() => {
        fetchBundles(1, setBundles)
            .then(() => setLoading(false))
            .catch(console.log)
    }, [])

    return <Screen title='Manage Bundles' key='/manage-bundles'>
        <h1>Manage Content Bundles</h1>
        <div className="row">
            <p style={{flex: 1}}>Create a new content bundle</p>
            <button className="course-button" onClick={createCourse}>Create</button>
        </div>
        <BundleList bundles={bundles} loading={loading}/>
    </Screen>
}

const fetchBundles = async  (page: number, setBundles: (bundles: ContentBundle[]) => void) => {
    let bundles = await AdminAPI.getBundleList()
    setBundles(bundles)
}

interface BundleListProps {
    bundles: ContentBundle[]
    loading: boolean
}

const BundleList: FC<BundleListProps> = ({ bundles, loading   }) => {
    if (loading) {
        return <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
            <LoadingIndicator />
        </div>
    } else {
        if (bundles.length === 0) {
            return <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
                <p style={{color: 'grey'}}>No bundles found</p>
            </div>
        } else {
            return <div className="user-list">
                {bundles.map((bundle, i) => <BundleCard key={`${i} ${bundle.bundleID}`} bundle={bundle} />)}
            </div>
        }
    }
}

interface BundleCardProps {
    bundle: ContentBundle
}

const BundleCard: FC<BundleCardProps> = ({ bundle }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/bundle_editor/${bundle.bundleID}`)
    }

    return <div className="user-card" onClick={handleClick}>
        <div style={{display: "flex", flexDirection: 'row', padding: "5px 0"}}>
            <p className="user-card-type">Bundle</p>
            <p className="user-card-date">{ dateToDMY(bundle.dateModified) }</p>
        </div>
        <p className="user-card-name">{ bundle.title }</p>
        <p className="user-card-email">Courses: { bundle.content.length }</p>
    </div>
}

export default AdminBundleListScreen