import GroupAccount, {GroupData} from "../../models/group";
import {FC, useState} from "react";
import GroupAccessItem from "./accessItem";
import {ConfirmationDialog} from "../common/alert";
import {useHistory} from "react-router-dom";
import {useUser} from "../../models/user";
import {dateToDMY} from "../../services/utils";

interface InfoProps {
    group: GroupData
}

const GroupInfoCard: FC<InfoProps> = ({ group }) => {
    const user = useUser()
    const history = useHistory()
    const [isLoading, setLoading] = useState(false)
    const [showLeaveDialog, setLeaveDialog] = useState(false)

    const leaveGroup = async () => {
        setLoading(true)
        await GroupAccount.leaveGroup(user.data.userID, group)
        history.goBack()
    }

    return <div className="questionnaire-card">
        <div className="row">
            <span style={{padding: "0 10px", borderRadius: "10px", background: '#ddd'}}>
                {group.maxMembers!==-1? `${group.numMembers}/${group.maxMembers}`:
                    group.numMembers}
            </span>
            <p style={{ margin: '0 5px'}}>Members</p>
        </div>
        <small>Available until: {dateToDMY(group.terminationDate)}</small>
        <br /><br />
        <h2>Information</h2>
        <p>{group.description}</p>
        <br />
        <h2>Content</h2>
        <hr />
        {group.access.length===0? <><br /><p>No content available</p></>:
            group.access.map(access => <GroupAccessItem accessStr={access} key={access} />)
        }
        <br />
        <button className="course-button" onClick={() => setLeaveDialog(true)}>Leave Group</button>
        {showLeaveDialog?
            <ConfirmationDialog
                isLoading={isLoading}
                title={`Leave Group`}
                message={`Are you sure you want to leave this group?`}
                onCancel={() => setLeaveDialog(false)}
                confirmText={`Leave`}
                onConfirm={leaveGroup}
            />:<></>
        }
    </div>
}

export default GroupInfoCard