import {FC} from "react";

interface ContentTabsProps {
    pdfMode: boolean
    setPdfMode: (mode: boolean)=>void
}

const CourseContentTabs: FC<ContentTabsProps> = ({ pdfMode, setPdfMode }) => {
    const handleClick = (newPdfMode: boolean) => {
        setPdfMode(newPdfMode)
    }
    return <div className='course-content-picker'>
        <p className={`course-tab-item ${!pdfMode?'active': ''}`} onClick={() => handleClick(false)}>Videos</p>
        <p className={`course-tab-item ${pdfMode?'active': ''}`}  onClick={() => handleClick(true)}>Slides</p>
    </div>
}

export default CourseContentTabs