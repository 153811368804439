import {FC, useEffect, useState} from "react";
import {CourseData} from "../../models/course";
import {courseEditorFiles} from "../../screens/admin/courseEditor";

interface DetailsProps {
    course: CourseData
    setCourse: (data: CourseData) => void
}

const EditCourseDetails: FC<DetailsProps> = ({ course, setCourse }) => {
    const [title, setTile] = useState(course.title)
    const [category, setCategory] = useState(course.category)
    const [description, setDescription] = useState(course.description)
    const [price, setPrice] = useState(course.price.toString())
    const [pdfFile, setPdfFile] = useState<File|null|undefined>(course.pdfURL!==''?new File([], course.pdfURL):null)

    useEffect(() => {
        const nPrice = parseFloat(price);
        let updatedPrice = nPrice
        if (isNaN(nPrice)) {
            updatedPrice = course.price
            // setPrice(course.price.toString())
        }
        setCourse({...course, title, category, description, price: updatedPrice, pdfURL: pdfFile?.name ?? ""})
    }, [title, category, description, price, pdfFile])

    const uploadPdf = () => {
        const picker = document.getElementById("editor-pdf-upload") as HTMLInputElement
        const selectedFile = picker.files?.item(0)
        if (selectedFile) {
            setPdfFile(selectedFile)
            courseEditorFiles.push(selectedFile)
        } else {
            window.alert("Selection cancelled")
        }
    }

    return <div className='course-card course-editor-card'>
        <small>{course.status}</small>
        <h3>Details</h3>
        <hr />
        <p>Title</p>
        <input
            value={title}
            onChange={(e) => setTile(e.target.value)}
            className='course-editor-field'
            placeholder='Title'
        />
        <br />
        <p>Category</p>
        <select
            className="course-editor-field"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder='none'
        >
            <option>none</option>
            <option>Cognitive Adaptability</option>
            <option>Broad Personality Traits</option>
            <option>Narrow Personality Traits</option>
        </select>
        <br />
        <p>Description</p>
        <textarea
            value={description}
            // TODO: handle this case better
            onChange={(e) => setDescription(e.target.value)}
            className='course-editor-field'
            placeholder='Description'
            style={{minHeight: "70px"}}
        />
        <br />
        <div className='row'>
            <p>Price (R)</p>
            <input
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className='course-editor-field'
                prefix={'R'}
                inputMode={'numeric'}
                type={"number"}
            />
        </div>
        <br />
        <p>Upload PDF</p>
        <label>
            <div className='course-editor-upload-row'>
                <p className='course-editor-file-name'>{pdfFile? pdfFile.name: "No file selected"}</p>
                <div className='course-editor-upload'>
                    Upload
                    <i className='material-icons'>upload</i>
                </div>
            </div>
            <input
                type={'file'}
                id="editor-pdf-upload"
                onChange={uploadPdf}
                style={{display: 'none'}}
            />
        </label>
    </div>
}

export default EditCourseDetails