import {useHistory} from "react-router-dom";
import {ContentBundle, CoursePersonalSummary} from "../../models/course";
import {FC} from "react";

interface ListCardProps {
    summary: CoursePersonalSummary
}

export const CourseListCard: FC<ListCardProps> = ({ summary }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/course/${summary.courseID}`)
    }

    const icons: Record<string, string> = {
        'Entrepreneurship': 'work',
        "Cognitive Adaptability": 'model_training',
        "Broad Personality Traits": 'face',
        "Narrow Personality Traits": 'emoji_symbols',
    }

    // Icons: work, face/emoji_symbols, sensors/model_training
    return <div className='course-card' onClick={handleClick}>
        <div className='course-category'>
            <i className='material-icons'>{icons[summary.category]}</i>
            <small>{summary.category}</small>
        </div>
        <h3>{summary.title}</h3>
        <hr />
        <p className='course-list-description'>{summary.description}</p>
        <br />
    </div>
}

interface BundleListCardProps {
    bundle: ContentBundle
}

export const BundleListCard: FC<BundleListCardProps> = ({ bundle }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/course_bundle/${bundle.bundleID}`)
    }

    return <div className='course-card' onClick={handleClick}>
        <div className='course-category'>
            <i className='material-icons'>collections_bookmark</i>
            <small>Bundle</small>
        </div>
        <h3>{bundle.title}</h3>
        <hr />
        <p className='course-list-description'>{bundle.description}</p>
        <br />
    </div>
}