import Screen from "../../components/common/screen";
import GroupAccount, {GroupData} from "../../models/group";
import {FC, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "../../components/common/loading";
import {useUser} from "../../models/user";
import AdminAPI from "../../services/admin";

const AdminGroupListScreen = () => {
    const user = useUser()
    const history = useHistory()
    const [groups, setGroups] = useState<GroupData[]>([])
    const [showLoading, setLoading] = useState(true)

    useEffect(() => {
        if (user.isAdmin) {
            AdminAPI.getGroupList()
                .then(list => {
                    setGroups(list)
                    setLoading(false)
                }).catch(e => {
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    const handleCreate = () => {
        history.push('/group_editor/new')
    }

    return <Screen key="/groups" title="Groups">
        <h2>Manage Groups</h2>
        <div className="row">
            <p style={{flex: 1}}>Create a new group</p>
            <button className="course-button" onClick={handleCreate}>Create</button>
        </div>
        <br />
        {showLoading? <LoadingIndicator />:
            groups.length !== 0 ? groups.map(group => <GroupListCard group={group} key={group.groupID}/>):
                <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
                    <p style={{color: 'grey'}}>No groups found</p>
                </div>
        }
    </Screen>
}

interface CardProps {
    group: GroupData
}

const GroupListCard: FC<CardProps> = ({ group }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push(`/group_editor/${group.groupID}`)
    }

    return <div className="user-card row" onClick={handleClick}>
        <div style={{flex: 1}}>
            <span style={{background: '#eee', borderRadius: '10px', padding: '3px 10px'}}>
                {group.groupCode}
            </span>
            <h3>{group.title}</h3>
            <p>{group.description}</p>
        </div>
        <i className="material-icons">chevron_right</i>
    </div>
}



export default AdminGroupListScreen