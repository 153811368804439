import {QuestionnaireData} from "./questionnaire";
import {ReportData} from "./report";
import {UserData} from "./user";
import {ContentBundle, CourseData} from "./course";
import {GroupData} from "./group";

interface AccessControl {
    // TODO: Add docs
    category: string
    reference: string
    detail1: string
    detail2: string
}

export function accessToString(access: AccessControl): string {
    if (access.detail1) {
        if (access.detail2) {
            return `${access.category.toLowerCase()}-${access.reference}-${access.detail1}-${access.detail2}`
        }
        return `${access.category.toLowerCase()}-${access.reference}-${access.detail1}`
    }
    return `${access.category.toLowerCase()}-${access.reference}`
}

export function accessFromString(str: string): AccessControl {
    const arr = str.split('-')
    return {
        category: arr[0]?.toLowerCase() ?? '',
        reference: arr[1] ?? '',
        detail1: arr[2] ?? '',
        detail2: arr[3] ?? ''
    }
}

export function newAccess(category: string, reference: string, detail1: string = '', detail2: string = ''): AccessControl {
    return accessFromString(`${category}-${reference}-${detail1}-${detail2}`)
}

export class AssessmentAccess implements AccessControl {
    category: string;
    detail1: string;
    detail2: string;
    reference: string;

    constructor(str: string) {
        const access = accessFromString(str)
        this.category = access.category
        this.reference = access.reference
        this.detail1 = access.detail1
        this.detail2 = access.detail2
    }

    // Create new access control object for a questionnaire
    static newQuestionnaire = (data: QuestionnaireData) => {
        const access = newAccess("questionnaire", data.questionnaireID, "MECA")
        return new AssessmentAccess(accessToString(access))
    }

    // Create new access control object for a report
    static newReport = (data: ReportData) => {
        const access = newAccess("report", data.questionnaireID, "MECA")
        return new AssessmentAccess(accessToString(access))
    }

    // Check if the given array has an object with access to a questionnaire
    static hasQuestionnaireAccess = (accessList: AccessControl[]) => {
        for (let i = 0; i < accessList.length; i++) {
            if (accessList[i].category === "questionnaire") {
                return true
            }
        }
        return false
    }

    // Check if the given array has an object with access to a report
    static hasReportAccess = (accessList: AccessControl[]) => {
        for (let i = 0; i < accessList.length; i++) {
            if (accessList[i].category === "report") {
                return true
            }
        }
        return false
    }

    // Remove questionnaire access and add report access
    static completeQuestionnaire = (accessList: AccessControl[], report: ReportData, user: UserData) => {
        for (let i = 0; i < accessList.length; i++) {
            if (accessList[i].category === 'questionnaire') {
                user.access[i] = AssessmentAccess.newReport(report).toString()
                return
            }
        }
    }

    toString = () => {
        return accessToString(this)
    }
}

export class CourseAccess implements AccessControl {
    category: string;
    detail1: string;
    detail2: string;
    reference: string;

    constructor(str: string) {
        const access = accessFromString(str)
        this.category = access.category
        this.reference = access.reference
        this.detail1 = access.detail1
        this.detail2 = access.detail2
    }

    // Create new access control object for a course
    static newCourse = (data: CourseData) => {
        const access = newAccess("course", data.courseID, data.title)
        return new CourseAccess(accessToString(access))
    }

    // Create new access control object for a course bundle
    static newBundle = (data: ContentBundle) => {
        const access = newAccess("bundle", data.bundleID, data.title)
        return new CourseAccess(accessToString(access))
    }

    // Check if the given array has an object with access to the given course
    static hasCourseAccess = (accessList: AccessControl[], courseID?: string) => {
        for (let i = 0; i < accessList.length; i++) {
            if (courseID) {
                if (accessList[i].category==="course" && accessList[i].reference===courseID) {
                    return true
                }
            } else {
                if (accessList[i].category==="course") {
                    return true
                }
            }
        }
        return false
    }

    toString = () => {
        return accessToString(this)
    }
}

export class GroupAccess implements AccessControl {
    category: string;
    detail1: string;
    detail2: string;
    reference: string;

    constructor(str: string) {
        const access = accessFromString(str)
        this.category = access.category
        this.reference = access.reference
        this.detail1 = access.detail1
        this.detail2 = access.detail2
    }

    // Create new access control object for a group
    static newGroup = (data: GroupData) => {
        const access = newAccess("group", data.groupID)
        return new GroupAccess(accessToString(access))
    }

    // Create new access control object for a group assessment
    static newAssessment = (groupID: string) => {
        const access = newAccess("group_assessment", 'MECA')
        return new AssessmentAccess(accessToString(access))
    }

    // Check if the given array has an object with access to the given group
    static hasGroupAccess = (accessList: AccessControl[], groupID: string) => {
        for (let i = 0; i < accessList.length; i++) {
            if (accessList[i].category==="group" && accessList[i].reference===groupID) {
                return true
            }
        }
        return false
    }

    toString = () => {
        return accessToString(this)
    }
}


export default AccessControl