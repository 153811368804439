import Screen from "../../components/common/screen";
import User from "../../models/user";
import {FC} from "react";

const AdminUserDetailsScreen = () => {
    const user = new User()

    return <Screen key="/user_details" title="User">
        <h2>{ user.name }</h2>
        <br />
        <div className="user-details-row">
            <UserDetailsCard user={user} />
            <PastAssessmentSummary user={user} />
        </div>
    </Screen>
}

interface UserDetailsProps {
    user: User
}

const UserDetailsCard: FC<UserDetailsProps> = ({ user }) => {
    return <div className="user-details">
        <p>Joined</p>
        <h4>{user.data.created.toLocaleDateString()}</h4>
        <hr />
        <DetailsItem title="Email" value={user.email} />
        <DetailsItem title="First Name" value={user.data.firstName} />
        <DetailsItem title="Last Name" value={user.data.lastName} />
        <DetailsItem title="Company" value={user.data.company} />
        <DetailsItem title="User Reference" value={user.data.userID} />
    </div>
}

interface DetailsItemProps {
    title: string
    value: string
}

const DetailsItem: FC<DetailsItemProps> = ({ title, value }) => {
    return <div className="user-details-item">
        <p className='user-details-title'>{title}</p>
        <p>{value}</p>
    </div>
}

interface PastAssessmentProps {
    user: User
}

// TODO: Complete
const PastAssessmentSummary: FC<PastAssessmentProps> = ({ user }) => {
    return <div className="assessment-summary">
        <p style={{padding: '10px  0'}}>Account: { user.data.accountType }</p>
        <AssessmentItem />
        {/*{ user.questionnaires.map(*/}
        {/*    q => <AssessmentItem />*/}
        {/*) }*/}
    </div>
}

const AssessmentItem = () => {
    // TODO: Add real data
    const isComplete = false

    return <>
        <hr />
        <div className="assessment-summary-row">
            <div>
                <p>25/12/2022</p>
                <p>{isComplete?" MC Adaptability score: 62": "In progress..."}</p>
            </div>
            {isComplete? <button>View</button>: <></>}
        </div>
    </>
}

export default AdminUserDetailsScreen