import Screen from "../../components/common/screen";
import HomeCard from "../../components/home/homeCard";
import {useUser} from "../../models/user";

const AdminHomeScreen = () => {
    const user = useUser()

    const handleClick = (route: string) => {
        window.location.hash = route
    }

    if (user.isAdmin) {
        return <Screen key="/" title="Home">
            <h2>Admin Panel</h2>
            <div className="home-grid">
                {/*<HomeCard*/}
                {/*    onClick={handleClick}*/}
                {/*    href="#/manage_users"*/}
                {/*    icon="person_add"*/}
                {/*    title="Manage Users"*/}
                {/*    description="View and manage all registered users."*/}
                {/*/>*/}
                <HomeCard
                    onClick={handleClick}
                    href="#/manage_courses"
                    icon="edit"
                    title="Manage Courses"
                    description="View and manage all published and unpublished courses."
                />
                <HomeCard
                    onClick={handleClick}
                    href="#/manage_bundles"
                    icon="collections_bookmark"
                    title="Manage Content Bundles"
                    description="View and manage all published and unpublished content bundles."
                />
                <HomeCard
                    onClick={handleClick}
                    href="#/manage_groups"
                    icon="group_add"
                    title="Manage Groups"
                    description="View and manage all group licenses and content."
                />
            </div>
        </Screen>
    } else {
        return <p style={{color: 'red'}}>Access Denied</p>
    }
}

export default AdminHomeScreen