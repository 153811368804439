import Screen from "../../components/common/screen";
import {FC, useEffect, useState} from "react";
import User from "../../models/user";
import LoadingIndicator from "../../components/common/loading";
import {useHistory} from "react-router-dom";

const AdminUserListScreen = () => {
    const [users, setUsers] = useState<User[]>([])
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const [moreAvailable, setMoreAvailable] = useState(true)

    // Load users when page first loads
    useEffect(() => {
        fetchUsers(1, setUsers)
            .then(() =>  setLoading(false))
            .catch(console.log)
    }, [])

    // TODO: update to new pagination mechanism
    // Update users when the page is changed
    useEffect(() => {
        setLoading(true)
        fetchUsers(page, setUsers)
            .then(() =>  setLoading(false))
            .catch(console.log)
    }, [page])

    return <Screen title='Manage Users' key='/manage-users' >
        <h1>Manage Users</h1>
        <UserList users={users} loading={loading} />
        {moreAvailable?
            <button className='course-button course-info-action'>Load More</button>: <></>
        }
    </Screen>
}

const fetchUsers = async  (page: number, setUsers: (users: User[]) => void) => {
    let users: User[] = new Array(10).fill(new User()) // []
    // TODO: implement
    setUsers(users)
}

interface UserListProps {
    users: User[]
    loading: boolean
}

const UserList: FC<UserListProps> = ({ users, loading   }) => {
    if (loading) {
        return <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
            <LoadingIndicator />
        </div>
    } else {
        if (users.length === 0) {
            return <div className="user-list" style={{justifyContent: "center", alignItems: "center"}}>
                <p style={{color: 'grey'}}>No users found</p>
            </div>
        } else {
            return <div className="user-list">
                {users.map((user, i) => <UserCard key={`${i} ${user.data.userID }`} user={user} />)}
            </div>
        }
    }
}

interface UserCardProps {
    user: User
}
const UserCard: FC<UserCardProps> = ({ user }) => {
    const history = useHistory()

    const handleClick = () => {
        history.push('/user_details')
    }

    return <div className="user-card" onClick={handleClick}>
        <div style={{display: "flex", flexDirection: 'row', padding: "5px 0"}}>
            <p className="user-card-type">{ user.data.accountType }</p>
            <p className="user-card-date">{ user.data.created.toLocaleDateString() }</p>
        </div>
        <p className="user-card-name">{ user.name }</p>
        <p className="user-card-email">{ user.email }</p>
    </div>
}

export default AdminUserListScreen