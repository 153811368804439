import {FC} from "react";
import User, {useUser} from "../../models/user";
import UserProfile from "./userProfile";
import {useHistory} from "react-router-dom";

// Represent each navigation link on the sidebar
interface SidebarLink {
    icon: string,
    title: string,
    route: string,
    pages: string[],
}

function getLinks(user: User): SidebarLink[] {
    const links: SidebarLink[] = [
        {icon: "", title: "Home", route: "/", pages: []},
    ]
    if (user.hasQuestionnaireAccess) {
        links.push({icon: "", title: "Readiness Assessment", route: "/assessment", pages: []})
    }
    if (user.hasReportAccess) {
        links.push({icon: "", title: "Report", route: "/report", pages: []})
    }
    if (user.isAdmin) {
        links.push({icon: "", title: "Admin Panel", route: "/admin", pages: ['/manage_courses', '/course_editor', '/course_preview', '/manage_groups', '/group_editor', '/manage_bundles', '/bundle_editor']})
    }
    if (user.hasCourseAccess()) {
        links.push({icon: "", title: "Courses", route: "/courses", pages: ['/course', '/course_bundle']})
    }
    links.push({icon: "", title: "Groups", route: "/groups", pages: ['/group']})
    links.push({icon: "", title: "Sign Out", route: "/login", pages: []})
    return links
}

// Handle the actual sidebar
interface SidebarProps {

}

const Sidebar: FC<SidebarProps> = () => {
    const user = useUser()
    const links = getLinks(user)

    return <div className="sidebar">
        <UserProfile />
        <br />
        <br />
        { links.map((link) => <SidebarItem key={link.route} link={link} />) }
    </div>
}

// Handle the UI for each individual navigation link
interface ItemProps {
    link: SidebarLink,
}

const SidebarItem: FC<ItemProps> = ({ link }) => {
    const history = useHistory()
    const user = useUser()
    const {title, route} = link

    let active = false
    const currentRoute = '/' + history.location.pathname.split('/')[1]
    if (currentRoute === route || link.pages.includes(currentRoute)) {
        active = true
    }

    // TODO: add custom actions
    const handleClick = async () => {
        if (title === 'Sign Out') {
            await user.signOut()
            history.push(route)
        } else {
            history.push(route)
        }
    }

    return <p className={`sidebar-link ${active? "active" : ''}`} onClick={handleClick}>{ title }</p>
}

export default Sidebar