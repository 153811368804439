import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDSW1Omuisu8wWYmaifsVo3aJ09eW2OI_E",
    authDomain: "apttick.firebaseapp.com",
    projectId: "apttick",
    storageBucket: "apttick.appspot.com",
    messagingSenderId: "922367173651",
    appId: "1:922367173651:web:0a084633c5347ccded8be6",
    measurementId: "G-B28ZSLXCFD"
};

const fbApp = initializeApp(firebaseConfig);

export default fbApp