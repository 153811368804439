import {Questionnaire} from "../../models/questionnaire";
import {FC} from "react";

interface TopBarProps {
    questionnaire: Questionnaire
}

const QuestionnaireTopBar: FC<TopBarProps> = ({ questionnaire }) => {
    const {page, progress} = questionnaire

    return <div className="questionnaire-progress-text">
        <p id="page-number">Page {page}</p>
        <p id="progress">{progress}%</p>
    </div>
}

export default QuestionnaireTopBar