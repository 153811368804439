import {FC} from "react";
import {GroupTabState} from "../../screens/groupPage";

interface ContentTabsProps {
    tab: GroupTabState
    setTab: (tab: GroupTabState)=>void
}

const GroupContentTabs: FC<ContentTabsProps> = ({ tab, setTab }) => {
    const handleClick = (newTab: GroupTabState) => {
        setTab(newTab)
    }
    return <div className='course-content-picker' style={{margin: '20px 0', alignSelf: 'center'}}>
        <p className={`course-tab-item ${tab===0?'active': ''}`} onClick={() => handleClick(0)}>Info</p>
        <p className={`course-tab-item ${tab===1?'active': ''}`}  onClick={() => handleClick(1)}>Manage</p>
        <p className={`course-tab-item ${tab===2?'active': ''}`}  onClick={() => handleClick(2)}>Members</p>
    </div>
}

export default GroupContentTabs