export default function LoadingIndicator() {
    return <div className="load-layout">
        <div className="load-container">
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
}