import Screen from "../components/common/screen";
import CourseListTabs from "../components/courseList/tabs";
import {BundleListCard, CourseListCard} from "../components/courseList/card";
import Course, {ContentBundle, CoursePersonalSummary, emptyBundle} from "../models/course";
import {useEffect, useState} from "react";
import {useUser} from "../models/user";
import LoadingIndicator from "../components/common/loading";

// TODO: display course bundles
const CourseListScreen = () => {
    const user = useUser()
    const [courses, setCourses] = useState<CoursePersonalSummary[]>([])
    const [bundles, setBundles] = useState<ContentBundle[]>([])
    const [showLoading, setLoading] = useState(true)

    useEffect(() => {
        user.getPersonalCourseList()
            .then(async _courses => {
                setCourses(_courses)
                const _bundles = await user.getBundleList()
                setBundles(_bundles)
                setLoading(false)
            }).catch(e => {
                console.warn(e)
                setLoading(false)
            })
    }, [])

    return <Screen key='/courses' title="Courses">
        <h2>Courses</h2>
        {/*<CourseListTabs />*/}
        <br />
        {showLoading ? <LoadingIndicator/> :
            !(courses.length === 0 && bundles.length === 0)  ?
                <div className='course-list-grid'>
                    {bundles.map(bundle => <BundleListCard key={`bundle: ${bundle.bundleID}`} bundle={bundle} />)}
                    {courses.map(summary => <CourseListCard key={`course: ${summary.courseID}`} summary={summary}/>)}
                </div> :
                    <div className="row" style={{justifyContent: "center", alignItems: "center", height: '100%'}}>
                        <p style={{color: 'grey'}}>No courses found</p>
                    </div>
        }
    </Screen>
}

export default CourseListScreen