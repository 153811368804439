import {CourseData} from "../../models/course";
import {FC, useEffect, useState} from "react";

interface QuestionProps {
    sectionIndex: number
    questionIndex: number
    course: CourseData
    setCourse: (data: CourseData) => void
}

const EditCourseQuestion: FC<QuestionProps> = ({ sectionIndex, questionIndex, course, setCourse, }) => {
    const mcQuestion = course.sections[sectionIndex].quiz[questionIndex]
    const correctIndex = mcQuestion.correctIndex
    const [question, setQuestion] = useState(mcQuestion.question)

    useEffect(() => {
        const sections = course.sections
        const quiz = sections[sectionIndex].quiz
        const q = quiz[questionIndex]
        q.question = question
        quiz[questionIndex] = q
        sections[sectionIndex].quiz = quiz
        setCourse({...course, sections})
    },[question])

    const removeQuestion = () => {
        const quiz = course.sections[sectionIndex].quiz
        quiz.splice(questionIndex, 1)
        const sections = course.sections
        sections[sectionIndex].quiz = quiz
        setCourse({...course, sections: sections})
    }

    const addOption = () => {
        const options = mcQuestion.options
        options.push("")
        const sections = course.sections
        sections[sectionIndex].quiz[questionIndex].options = options
        setCourse({...course, sections: sections})
    }

    const setCorrect = (index: number) => {
        const sections = course.sections
        sections[sectionIndex].quiz[questionIndex].correctIndex = index
        setCourse({...course, sections: sections})
    }

    return <div>
        <button onClick={removeQuestion} className='course-button' style={{alignSelf: 'end'}}>Remove Question {questionIndex+1}</button>
        <br />
        <p>Question {questionIndex+1}</p>
        <input
            style={{width: "100%"}}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            className='course-editor-field'
            placeholder='Question'
        />
        <br /><br />
        <div className='row'>
            <p>Options</p>
            <button onClick={addOption} className='course-button'>Add</button>
        </div>
        <br />
        {mcQuestion
            .options
            .map((op, i) =>
                <AnsOption
                    key={`Q ${sectionIndex}-${questionIndex}-${i}`}
                    course={course}
                    setCourse={setCourse}
                    sectionIndex={sectionIndex}
                    questionIndex={questionIndex}
                    optionIndex={i}
                    correctIndex={correctIndex}
                    setCorrect={setCorrect}
                />
            )
        }
        <hr />
    </div>
}

interface OptionProps {
    course: CourseData
    setCourse: (data: CourseData) => void
    sectionIndex: number
    questionIndex: number
    optionIndex: number
    correctIndex: number
    setCorrect: (i: number)=>void
}

const AnsOption: FC<OptionProps> = ({
    course,
    setCourse,
    sectionIndex,
    questionIndex,
    optionIndex,
    correctIndex,
    setCorrect,
}) => {
    const question = course.sections[sectionIndex].quiz[questionIndex]
    const [option, setOption] = useState(question.options[optionIndex])
    // const option = question.options[optionIndex]

    // // TODO: fix
    // useEffect(() => {
    //     const sections = course.sections
    //     const quiz = sections[sectionIndex].quiz
    //     const question = quiz[questionIndex]
    //     const options = question.options
    //     options[optionIndex] = option
    //     quiz[questionIndex] = question
    //     sections[sectionIndex].quiz = quiz
    //     setCourse({...course, sections})
    // },[option])

    // useEffect(() => {
    //     window.alert('Updated')
    // },[course])

    const handleChange =  (value: string) => {
        setOption(value)
        const sections = course.sections
        const quiz = sections[sectionIndex].quiz
        const question = quiz[questionIndex]
        const options = question.options
        options[optionIndex] = value
        quiz[questionIndex] = question
        sections[sectionIndex].quiz = quiz
        setCourse({...course, sections})
    }

    const removeOption = () => {
        const options = [...course.sections[sectionIndex].quiz[questionIndex].options]
        options.splice(optionIndex, 1)
        const sections = [...course.sections]
        sections[sectionIndex].quiz[questionIndex].options = options
        setCourse({...course, sections: [...sections]})
    }

    return <div className='row'>
        <input
            value={optionIndex}
            checked={correctIndex === optionIndex}
            onChange={(e) => setCorrect(optionIndex)}
            name={`${sectionIndex}-${questionIndex}`}
            type={"radio"}
        />
        <input
            key={`option ${sectionIndex}-${questionIndex}-${optionIndex}`}
            value={option}
            onChange={(e) => handleChange(e.target.value)}
            style={{flex: '1'}}
            className='course-editor-field'
            placeholder={`Option ${optionIndex+1}`}
            name={`${sectionIndex}-${questionIndex} option`}
        />
        <button onClick={removeOption} className='course-button'>
        <i className='material-icons'>delete</i>
        </button>
    </div>
}

export default EditCourseQuestion