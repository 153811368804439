import {MCQuestion} from "../../models/course";
import {FC, useEffect, useState} from "react";

interface MCQuestionProps {
    index: number
    question: MCQuestion
    action: ()=>void
}

const MCQuestionContent: FC<MCQuestionProps> = ({ question, action, index }) => {
    const [ans, setAns] = useState(question.selectedIndex)
    // TODO: test answering

    return <>
        <p>Question {index+1}</p>
        <hr />
        <br /><br /><br /><br />
        <p className='quiz-question-text'>{question.question}</p>
        <br /><br /><br /><br />
        <div key={`${index}) ${question.question}`} className='quiz-option-container'>
            {question.options.map((s, index) => <AnswerOption key={`${index}) ${s}`} question={question} selectedAns={ans} setAnswer={setAns} index={index} />)}
        </div>
        <br />
        {ans !== undefined? <button className='course-button course-info-action' onClick={action}>Continue</button>: <></>}
    </>
}

interface AnswerPops {
    index: number
    question: MCQuestion
    selectedAns?: number
    setAnswer: (ans: number)=>void
}

const AnswerOption: FC<AnswerPops> = ({ question, setAnswer, selectedAns, index }) => {
    const isSelected = (selectedAns === index)

    const handleClick = () => {
        question.selectedIndex = index
        setAnswer(index)
    }

    return <div className={isSelected? 'quiz-answer-selected': 'quiz-answer'} onClick={handleClick}>{index+1}) {question.options[index]}</div>
}

export default MCQuestionContent