import {FC, useState} from "react";
import BackButton from "./back";

interface Props {
    title?: string,
    showBack?: boolean,
}

const Appbar: FC<Props> = ({ title = "Testing", showBack }) => {
    let showMenu = false

    // Check if the page needs a back button
    if (showBack === undefined) {
        showBack = window.location.hash !== "#/";
    }

    if (window.screen.width < 900) {
        showMenu = true
    }

    // Return the UI to be rendered
    return <nav>
        {showBack? <BackButton />: <></>}
        <h2>{ title }</h2>
        {showMenu? <MenuButton />: <></>}
    </nav>
}

function MenuButton() {
    const [open, setOpen] = useState(false)

    const onClick = () => {
        const menu = document.getElementsByClassName("sidebar")[0]
        if (!open) {
            menu.setAttribute("style", "left: 0")
        } else {
            menu.setAttribute("style", "left: -100%")
        }
        setOpen(!open)
    }

    return <button className="back-button" onClick={onClick}>
        <i className="material-icons">
            {open? "close": "menu"}
        </i>
    </button>
}

export default Appbar