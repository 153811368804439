import * as logo from "../assets/images/icon3.jpg";
import {isEmpty} from "../services/utils";
import {useState} from "react";
import User from "../models/user";
import {authErrorHint} from "../services/auth";
import LoadingIndicator from "../components/common/loading";

const ResetPasswordScreen = () => {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [showLoading, setLoading] = useState(false)

    const handleSubmit = async () => {
        if (isEmpty(email)) {
            setError("Enter your email address")
        } else if (!email.includes("@")) {
            setError("Please enter a valid email address")
        } else {
            setLoading(true)
            try {
                await User.resetPassword(email)
                window.location.hash = '/login'
            } catch (e) {
                const eText = authErrorHint(e)
                setError(eText)
            }
            setLoading(false)
        }
    }

    return <div className="login-card">
        <div className="login-about">
            <h1>AptTick</h1>
            <small>Apt businesses tick all the boxes</small>
            <hr />
            <p>Our goal is to develop a large pool of entrepreneurs beyond a single business venture and build a future of successful entrepreneurial culture and sustainable economic growth.</p>
        </div>
        <div className="login-form">
            <div className="row" style={{width: "100%"}}>
                <img className="login-logo" src={logo.default} alt='AptTick Logo' />
                <h1>Reset Password</h1>
            </div>
            <br />
            <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <small style={{color: 'red', padding: '0 10px'}}>{error}</small>
            <div style={{height: '50px'}} />
            {showLoading? <LoadingIndicator />:
                <div className="row" style={{width: '100%'}}>
                    <button className="login-btn-secondary" onClick={() => window.history.back()}>Cancel</button>
                    <button className="login-btn-primary" onClick={handleSubmit}>Reset</button>
                </div>
            }
        </div>
    </div>
}

export default ResetPasswordScreen