import GroupAccount from "../../models/group";
import {FC, useEffect, useState} from "react";
import Alert, {AlertDialog, ConfirmationDialog} from "../common/alert";
import {useUser} from "../../models/user";

interface ManagerProps {
    group: GroupAccount
}

// TODO: allow release of new questionnaires
const GroupManagerContent: FC<ManagerProps>  = ({ group }) => {
    const user = useUser()
    const [title, setTitle] = useState(group.data.title)
    const [description, setDescription] = useState(group.data.description)
    const [shouldRelease, setShouldRelease] = useState(false)
    const [showAlert, setAlertDialog] = useState(false)
    const [showConfirmation, setConfirmationDialog] = useState(false)
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        if (shouldRelease) {
            if (group.data.availableQuestionnaires === 0) {
                setShouldRelease(false)
            }
        }
    }, [shouldRelease])

    const confirmRelease = async () => {
        if (shouldRelease) {
            setConfirmationDialog(true)
        } else {
            await saveEdit()
        }
    }

    const saveEdit = async () => {
        setLoading(true)
        setAlertDialog(true)
        const groupData = {...group.data}
        groupData.title = title
        groupData.description = description
        await GroupAccount.updateInfo(user.data, groupData, shouldRelease)
        setConfirmationDialog(false)
        setShouldRelease(false)
        setLoading(false)
    }

    const cancelRelease = async () => {
        setShouldRelease(false)
        await saveEdit()
    }

    return <div className="course-card">
        <p>Group code
            <span
                style={{background: '#eee', borderRadius: '10px', padding: '3px 10px', margin: '10px'}}
            >{group.data.groupCode}</span>
        </p>
        <br />
        <p>Title</p>
        <input
            className='course-editor-field'
            placeholder="Group Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <p>Description</p>
        <textarea
            className='course-editor-field'
            placeholder='Group Description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        {/* <div className="row">
            <div style={{flex: '1'}}>
                <div className="row">
                    <div style={{marginRight: '5px', height: '10px', width: '10px', background: 'yellowgreen', borderRadius: '5px', display: shouldRelease? 'block': 'none'}} />
                    <p>Release Questionnaire</p>
                </div>
                {shouldRelease? <small style={{color: 'yellowgreen'}}>Set to release</small>:
                    <small style={{color: group.data.availableQuestionnaires===0?'red':'auto'}}>{group.data.availableQuestionnaires} remaining</small>
                }
            </div>
            <button className="course-button" onClick={() => setShouldRelease(!shouldRelease)}>
                {shouldRelease? "Cancel": "Release"}
            </button>
        </div> */ }
        <button className="course-button course-info-action" style={{alignSelf: 'center'}} onClick={confirmRelease} >Save</button>
        {showAlert?
            <AlertDialog
                isLoading={isLoading}
                title={'Saved'}
                message={"Your changes have been saved"}
                onClose={() => setAlertDialog(false)}
            />:<></>
        }
        {showConfirmation ?
            <ConfirmationDialog
                title={'Release Questionnaire'}
                message={'Are you sure you want to release a new questionnaire?'}
                onCancel={cancelRelease}
                confirmText={'Release'}
                onConfirm={saveEdit}
            /> : <></>
        }
    </div>
}

export default GroupManagerContent