import Screen from "../components/common/screen";
import ReportCard from "../components/report/reportCard";
import Report, {Category} from "../models/report";
import {useEffect, useState} from "react";
import LoadingIndicator from "../components/common/loading";
import User, {useUser} from "../models/user";
import {useHistory} from "react-router-dom";
import AptTickAPI from "../services/api";

const ReportScreen = () => {
    const history = useHistory()
    const currentUser = useUser()
    const [user, setUser] = useState(currentUser)
    const [showLoading, setLoading] = useState(true)
    const [categories, setCategories] = useState<Category[]>([])

    useEffect(() => {
        // TODO: add security check for data access
        const arr = history.location.pathname.split('/')
        const id = arr[arr.length-1]
        if (id && arr.length === 3) {
            // TODO: test group member report viewing
            AptTickAPI.getUser(id).then(async (_user) => {
                setUser(new User(_user))
                Report.fetch(_user).then((report) => {
                    setCategories(report.data)
                    setLoading(false)
                }).catch(e => {
                    setLoading(false)
                })
            })
        } else {
            Report.fetch(user.data).then((report) => {
                setCategories(report.data)
                setLoading(false)
            }).catch(e => {
                setLoading(false)
            })
        }
    }, [])

    return <Screen key="/report" title="Report">
        <p>Report</p>
        <h2>{user.name}</h2>
        <br />
        {showLoading? <LoadingIndicator />:
            categories.length!==0? categories.map(category => <div key={category.name}>
                <h2>{ category.name }</h2>
                <p className="report-description">{ category.description }</p>
                <div className="report-grid">
                    { category.traits.map(
                        trait => <ReportCard
                            key={trait.name}
                            title={ trait.name }
                            description={ trait.description }
                            score={ trait.score }
                            rank={ trait.rank }
                        />) }
                </div>
            </div>): <p>This report is not yet available</p>
        }
    </Screen>
}

export default ReportScreen