import * as logo from '../assets/images/icon3.jpg'
import {useState} from "react";
import User from "../models/user";
import {isEmpty} from "../services/utils";
import {authErrorHint} from "../services/auth";
import LoadingIndicator from "../components/common/loading";

interface Validation {
    email: string
    password: string
}

interface Fields {
    email: string
    password: string
}

const LoginScreen = () => {
    const [showLoading, setLoading] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const handleSubmit = async () => {
        const validator = validate({email, password})
        let error = ''
        Object.values(validator).reverse().forEach((item) => {
            if (item.length > 0) {
                error = item
            }
        })
        if (isEmpty(error)) {
            setLoading(true)
            try {
                await User.logIn(email, password)
                window.location.hash = '/'
            } catch (e) {
                error = authErrorHint(e)
            }
            setLoading(false)
        }
        setError(error)
    }

    return <div className="login-card">
        <div className="login-about">
            <h1>AptTick</h1>
            <small>Apt businesses tick all the boxes</small>
            <hr />
            <p>Our goal is to develop a large pool of entrepreneurs beyond a single business venture and build a future of successful entrepreneurial culture and sustainable economic growth.</p>
        </div>
        <div className="login-form">
            <div className="row" style={{width: "100%"}}>
                <img className="login-logo" src={logo.default} alt='AptTick Logo' />
                <h1>Welcome</h1>
            </div>
            <br />
            <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={async (e) => {
                    if (e.key === "Enter") {
                       await handleSubmit()
                    }}
                }
            />
            <a href="#/reset_password" className="forgot-password">Forgot password</a>
            <div style={{height: '25px'}} />
            <p style={{color: 'red', textAlign: 'center'}}>{ error }</p>
            <div style={{height: '25px'}} />
            { showLoading? <LoadingIndicator />:
                <div className="row" style={{width: '100%'}}>
                    <button className="login-btn-secondary" onClick={() => window.location.hash = '/signup'}>Create
                        account
                    </button>
                    <button className="login-btn-primary" onClick={handleSubmit}>Log In</button>
                </div>
            }
        </div>
    </div>
}

function validate(fields: Fields): Validation {
    const {email, password} = fields
    let emailError = ''
    let passwordError = ''

    // Validate email address
    if (isEmpty(email)) {
        emailError = "Enter your email address"
    } else if (!email.includes("@")) {
        emailError = "Enter a valid email address"
    }
    // Validate password
    if (password.length < 1) {
        passwordError = "Please enter a password"
    }

    return {email: emailError, password: passwordError}
}

export default LoginScreen