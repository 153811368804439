interface Props {
    next: () => void
    previous: () => void
}

export default function QuestionnaireBottomBar({ next, previous }: Props) {
    return <div className="questionnaire-bottom-bar">
        <div className="next-button"  onClick={previous}>
            <i className="material-icons" style={{paddingRight: '10px', width: '24px'}}>arrow_backward</i>
            <span>Previous</span>
        </div>
        <div className="next-button" onClick={next}>
            <span>Next</span>
            <i className="material-icons" style={{paddingLeft: '10px'}}>arrow_forward</i>
        </div>
    </div>
}