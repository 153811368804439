export const isEmpty = (item: string) => item.trim().length <= 0

export const isNotEmpty = (item: string) => item.trim().length > 0

export const capitalise = (str: string, everyWord: boolean = false) => {
    if (everyWord) {
        return str.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ')
    } else {
        return str[0].toUpperCase() + str.slice(1)
    }
}

export function dateToYMD(date: Date) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export function dateToDMY(date: Date) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return `${(d <= 9 ? '0' + d : d)}/${(m<=9 ? '0' + m : m)}/${y}`
}