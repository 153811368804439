import Screen from "../components/common/screen";
import LoadingIndicator from "../components/common/loading";
import {FC, useEffect, useState} from "react";
import GroupAccount, {demoGroupAccount, GroupData} from "../models/group";
import GroupInfoCard from "../components/group/infoContent";
import GroupContentTabs from "../components/group/tabs";
import GroupManagerContent from "../components/group/manageContent";
import GroupMemberList from "../components/group/memberList";
import {useUser} from "../models/user";
import AptTickAPI from "../services/api";
import {useHistory} from "react-router-dom";

export enum GroupTabState {
    info,
    manage,
    members,
}

const GroupInfoPage = () => {
    const user = useUser()
    const history = useHistory()
    const [group, setGroup] = useState<GroupAccount>()
    const [showLoading, setLoading] = useState(true)
    const [tab, setTab] = useState(GroupTabState.info)

    useEffect(() => {
        const arr = history.location.pathname.split('/')
        const groupID = arr[arr.length-1]
        AptTickAPI.getGroupData(groupID).then(_group => {
            setGroup(new GroupAccount(_group))
            setLoading(false)
        }).catch(e => {
            setLoading(false)
        })
    }, [])

    if (group) {
        return <Screen title="Group" key="/group">
            <p>Group</p>
            <h2>{group.data.title}</h2>
            {
                group.data.admins.includes(user.data.userID)|| user.isAdmin? <GroupContentTabs tab={tab} setTab={setTab}/>: <br />
            }
            <Content tab={tab} group={group} />
        </Screen>
    } else {
        return <Screen title="Group" key="/group">
            <h2>Group</h2>
            <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {showLoading? <LoadingIndicator />:
                    <p>Group not found</p>
                }
            </div>
        </Screen>
    }
}

interface ContentProps {
    tab: GroupTabState
    group: GroupAccount
}

const Content: FC<ContentProps> = ({ tab, group }) => {
    if (tab===GroupTabState.members) {
        return <GroupMemberList group={group} />
    } else if (tab===GroupTabState.manage) {
        return <GroupManagerContent group={group} />
    }
    return <GroupInfoCard group={group.data} />
}

export default GroupInfoPage