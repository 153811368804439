import AptTickAPI from "../services/api";
import User, {UserData} from "./user";
import AccessControl from "./access";

export interface GroupData {
    groupID: string
    title: string
    description: string
    groupCode: string
    admins: string[]
    numMembers: number
    maxMembers: number
    autoAcceptRequests: boolean
    access: string[]
    enabled: boolean
    totalQuestionnaires: number
    availableQuestionnaires: number
    terminationDate: Date
    error: string
    dateCreated: Date
    // TODO: add customisations
}

export default class GroupAccount {
    constructor(public data: GroupData) {}

    static async joinGroup(user: UserData, groupCode: string): Promise<GroupData> {
        return await AptTickAPI.joinGroup(user, groupCode)
    }

    static async syncUserData(user: User): Promise<string[]> {
        // TODO: handle access control
        const summaries = await GroupAccount.getGroupList(user)
        const accessList: string[] = []
        for (const summary of summaries) {
            if (summary.available) {
                const group = await GroupAccount.getGroup(summary.groupID)
                await AptTickAPI.saveGroupSummary(user.data, group.data, summary)
                // Update user access
                group.data.access.forEach(accessStr => {
                    if (accessStr.startsWith('group_assessment')) {
                        // TODO: handle questionnaire releases
                    } else if(!accessList.includes(accessStr)) {
                        accessList.push(accessStr)
                    }
                })
            }
        }
        return accessList
    }

    static async leaveGroup(userID: string, group: GroupData) {
        const user = new User(await AptTickAPI.leaveGroup(userID, group))
        await user.save()
    }

    static async getGroupList(user: User): Promise<GroupInfoSummary[]> {
        return await AptTickAPI.getGroupList(user)
    }

    static async getGroup(groupID: string): Promise<GroupAccount> {
        return new GroupAccount(await AptTickAPI.getGroupData(groupID))
    }

    static async updateInfo(user: UserData, group: GroupData, releaseAssessment: boolean) {
        await AptTickAPI.updateGroupInfo(group)
        if (releaseAssessment) {
            await AptTickAPI.releaseQuestionnaire(user, group)
        }
    }

    getMembers = async (): Promise<GroupMember[]> => {
        return await AptTickAPI.getGroupMembers(this.data.groupID, 0, 10)
    }

    deleteMember = async (userID: string) => {
        await AptTickAPI.leaveGroup(userID, this.data)
    }

    updateAdmin = async (member: GroupMember): Promise<GroupMember> => {
        return await AptTickAPI.makeGroupMemberAdmin(this.data, member)
    }
}

export interface GroupInfoSummary {
    groupID: string
    title: string
    description: string
    access: string[]
    available: boolean
    dateJoined: Date
}

export interface GroupMember {
    userID: string
    firstName: string
    lastName: string
    email: string
    membershipStatus: string // "admin", "member", "request_sent", "request_received"
    dateJoined: Date
}

export interface GroupVersionHistory {
    userID: string
    email: string
    dateModified: Date
    data: GroupData
}

export interface GroupAssessmentRelease {
    userID: string
    assessmentType: string
    date: Date
}

export const demoGroupAccount = new GroupAccount({
    groupID: 'none',
    title: 'Demo Group',
    description: 'This group account exists purely for demonstration purposes.',
    groupCode: 'demo',
    autoAcceptRequests: true,
    enabled: true,
    numMembers: 0,
    maxMembers: 0,
    admins: [],
    access: ['assessment-000-MECA', 'bundle-000-Cognitive Adaptability Crash Course', 'course-000-Agreeableness'],
    totalQuestionnaires: 2,
    availableQuestionnaires: 1,
    terminationDate: new Date(),
    error: '',
    dateCreated: new Date(),
})

export const emptyGroupData: GroupData = {
    groupID: 'new',
    title: '',
    groupCode: '',
    availableQuestionnaires: 0,
    admins: [],
    access: [],
    description: '',
    dateCreated: new Date(),
    error: '',
    terminationDate: new Date(),
    numMembers: 0,
    maxMembers: 0,
    totalQuestionnaires: 0,
    enabled: false,
    autoAcceptRequests: true,
}