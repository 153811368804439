import {CourseData, demoCourse, Section} from "../../models/course";
import {FC, useEffect, useState} from "react";
import AptTickAPI from "../../services/api";
import LoadingIndicator from "../common/loading";

interface VideoViewerProps {
    course: CourseData
    section: Section
    next: ()=>void
    prev: ()=>void
}

const CourseVideoViewer: FC<VideoViewerProps> = ({ course, section, next, prev }) => {
    const [videoUrl, setVideoUrl] = useState(section.videoURL)
    // const [thumbURL, setThumbURL] = useState(section.thumbnailURL)
    const [showLoading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        AptTickAPI.getFileURL(`Courses/${course.courseID}/${section.videoURL}`).then(async url => {
            setVideoUrl(`${url}`)
            // const thumb = await AptTickAPI.getFileURL(`Courses/${course.courseID}/${section.thumbnailURL}`)
            // setThumbURL(`${thumb}`)
            setLoading(false)
        })
    }, [])

    return <div className="course-card">
        <h3>{section.title}</h3>
        <p>{section.description}</p>
        <br />
        {showLoading ? <LoadingIndicator/> :
            <video
                className='course-video'
                controls={true}
                controlsList="nodownload"
                preload='none'
                width="100%"
                // poster={thumbURL}
                src={videoUrl}
            >
                <p>Your browser does not support video playback</p>
            </video>
        }
        <br />
        <div className="course-nav-row">
            <button className="course-button" onClick={prev}>
                <i className='material-icons'>chevron_left</i>
                <p>Back</p>
            </button>
            <button className="course-button" onClick={next}>
                <p>Next</p>
                <i className='material-icons'>chevron_right</i>
            </button>
        </div>
    </div>
}

export default CourseVideoViewer