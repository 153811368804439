import {QuestionnaireData} from "./questionnaire";
import {UserData} from "./user";
import AptTickAPI from "../services/api";

export interface ReportData {
    userID: string
    questionnaireID: string
    dateCreated: Date,
    score: number,
    data: {
        [key: string]: TraitData[]
    }
}

export default class Report {
    public dateCreated: Date
    public average: number
    public data: Category[]

    constructor({dateCreated, score, data}: ReportData) {
        this.dateCreated = dateCreated
        this.average = score

        let i = 0
        const order = ['ca', 'agreeableness', 'neuroticism', 'openness', 'extraversion', 'conscientiousness']
        this.data = new Array(order.length)
        order.forEach(key => {
            const traits = data[key]
            this.data[i] = new Category({key, traits})
            i++
        })
    }

    static generate(questionnaire: QuestionnaireData): ReportData {
        const results = calculateResults(questionnaire)
        const score = results['ca']
            .map(trait => trait.score)
            .reduce((total, value) => total + value)
        return {
            userID: questionnaire.userID,
            questionnaireID: questionnaire.questionnaireID,
            dateCreated: new Date(),
            data: results,
            score: score
        }
    }

    static async fetch(user: UserData): Promise<Report> {
        const data = await AptTickAPI.getReport(user)
        return new Report(data)
    }
}

interface CategoryData {
    key: string
    traits: TraitData[]
}

export class Category {
    public name: string
    public description: string
    public traits: Trait[]

    constructor({key, traits}: CategoryData) {
        const {name, description} = Category.definitions[key]
        this.name = name
        this.description = description
        this.traits = traits.map(trait => new Trait(trait))
    }

    static definitions: {[key: string]: {name: string, description: string}} = {
        "ca": {
            name: "Cognitive Adaptability",
            description: "Cognitive adaptability is defined as the ability to effectively and appropriately change decision policies (i.e., to learn) when given feedback (inputs) from the environmental context in which cognitive processing is embedded.",
        },
        "agreeableness": {
            name: "Agreeableness",
            description: "An agreeable individual is cooperative, caring, gentle, trusting, forgiving, compliant, and empathetic, whereas a disagreeable individual is uncooperative, suspicious, egocentric, self- centred, sceptical towards others’ intentions, and manipulative. While high agreeableness can decrease the entrepreneurial failure rate, “high levels of agreeableness may inhibit one’s willingness to drive hard bargains, look out for one’s own self-interest and influence or manipulate others for one’s own advantage”.",
        },
        "neuroticism": {
            name: "Neuroticism",
            description: "Emotionally stable individuals stand out as being calm, self-confident, stress-tolerant, relaxed and even-tempered. On the contrary, the common traits of neurotic individuals include anxiety, depression, impulsiveness, hostility, vulnerability, and self-consciousness. Entrepreneurs are confronted with situations that generate stress. Indeed, numerous factors such as “the work environment, workload, work-family conflict and financial risk of starting and running a new business venture can produce physical and psychological stress”. In this regard, emotional stability represents the capacity to think positively in all circumstances and deal with stress. In addition, entrepreneurs distinguished by this personality trait are self-confident, particularly in the face of uncertainty. They are also likely to positively influence employees and have good relations with them and all stakeholders. Neuroticism is likely to decrease the propensity to take risks and reduce the ability of individuals to build social capital.",
        },
        "openness": {
            name: "Openness To Experience",
            description: "Individuals that are open to experience are untraditional, imaginative, and intellectually curious. They tend to appreciate novelty and explore new ways of doing things, making them creative and innovative. Individuals high in openness to experience may have positive attitudes towards learning experiences…“successful entrepreneurial performance is facilitated by a disposition towards new or unconventional ideas, values, and actions”. However, less open individuals are conservative, preferring routines and the status quo, which are obviously unfavourable to entrepreneurial activities.",
        },
        "extraversion": {
            name: "Extraversion",
            description: "Extraverts are enthusiastic, cheerful, assertive, energetic, sociable, and seek opportunities for excitement. On the contrary, introverts prefer to be alone, are discreet, quiet, and characterized by a lack of social involvement. By embarking on a new business venture, entrepreneurs are expected to be in extensive contact with the internal and external partners of the business. Extraverted entrepreneurs build solid networks to facilitate their access to external resources. The sociability of extraverts allows them to mobilize others and develop extensive social interactions. Additionally, extraverted entrepreneurs enhance employees’ motivation and commitment and improve the work environment. Extraverts are better at performing managerial tasks thanks to their sociability.",
        },
        "conscientiousness": {
            name: "Conscientiousness",
            description: "Conscientiousness is one of the five traits of both the Five Factor Model and is an aspect of what has traditionally been referred to as having character. Conscientious individuals are generally hard-working, and reliable. They are also likely to be conformists.[2] When taken to an extreme, they may also be \"workaholics\", perfectionists, and compulsive in their behavior.[3] People who score low on conscientiousness tend to be laid back, less goal-oriented, and less driven by success; they also are more likely to engage in antisocial and criminal behavior.",
        }
    }
}

interface TraitData {
    name: string
    score: number
    rank: string
}

export class Trait {
    public name: string
    public description: string
    public rank: string
    public score: number

    constructor({name, rank, score}: TraitData) {
        this.name = name
        this.rank = rank
        this.score = score
        this.description = Trait.descriptions[name]
    }

    static descriptions: {[name: string]: string} = {
        // CA
        "Goal Orientation": "The extent to which the individual interprets environmental variations in light of a wide variety of personal, social, and organizational goals.",
        "Monitoring": "Seeking and using feedback to re-evaluate goal orientation, metacognitive knowledge, metacognitive experience, and metacognitive choice for the purpose of ‘managing’ a changing environment.",
        "Metacognitive Choice": "The extent to which the individual engages in the active process of selecting from multiple decision frameworks the one that best interprets, plans, and implements a response for the purpose of ‘managing’ a changing environment.",
        "Current Metacognitive Experience": "The extent to which the individual relies on current idiosyncratic experiences, emotions and information when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goals to ‘manage’ a changing environment.",
        "Prior Metacognitive Experience": "The extent to which the individual relies on previous idiosyncratic experiences, emotions, information and intuition when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goals to ‘manage’ a changing environment.",
        "Prior Metacognitive Knowledge": "The extent to which the individuals rely on what is previously known about oneself, other people and strategy when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goal to manage a changing environment.",
        "Current Metacognitive Knowledge": "The extent to which the individuals rely on what is currently known about oneself, other people and strategy when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goal to manage a changing environment.",
        // Agreeableness
        "Meekness": "The extent to which an individual is patient, long-suffering, forbearing and resigned.",
        "Pro-Social Orientation": "The extent to which an individual is friendly, kind-hearted, pleasant, considerate helpful and warm-hearted.",
        "Non-Antagonistic Orientation": "The extent to which an individual is not grouchy, arrogant, irritable, hot-tempered, hostile and argumentative.",
        // Neuroticism
        "Depression": "The extent to which an individual is lonely, fearful, anxious and depressed. (A high score means low levels of depression)",
        "Self-Reproach": "The extent to which an individual is sad, afraid, insecure, depressed and troubled.",
        "Negative Affect": "The extent to which an individual is depressed, sad, worried and afraid and insecure.",
        // Openness
        "Unconventionality": "The extent to which an individual is conservative, traditional and unusual.",
        "Intellectual Interest": "The extent to which an individual is intellectual, philosophical, deep, intelligent and knowledgeable.",
        "Aesthetic Interest": "The extent to which an individual is artistic, imaginative, tolerant and curious.",
        // Extraversion
        "Activity": "The extent to which an individual is energetic, active, exciting, lively, busy, powerful, awesome and influential.",
        "Positive Affect": "The extent to which an individual is joyful, cheerful, laughing, positive, glad and lively.",
        "Sociability": "The extent to which an individual is active, gets along with others and is talkative.",
        // Conscientiousness
        "Orderliness": "The extent to which an individual is organized, efficient, neat, systematic and thorough.",
        "Goal Striving": "The extent to which an individual is dedicated, ambitious, persistent and productive.",
    }

    get color() {
        switch (this.rank) {
            case "Needs Work":
                return 'red'
            case "Below Average":
                return 'orange'
            case "Above Average":
                return '#cddc39'
            case "Excellent":
                return '#4caf50'
            default:
                return 'gray'
        }
    }
}

function getScore(inAns: number[]): number {
    let score = 0;
    for(let i = 0; i<inAns.length; i++){
        score += inAns[i];
    }
    score = score/inAns.length;
    score = score*2500;
    score = Math.round(score);
    score = score/100;
    return score;
}

function getRank(score: number, range: number[], mean: number) {
    //var colour;
    let rank;
    if (score < range[0]){
        //colour = "red";
        rank = "Needs Work"
    }
    else if (score >= range[1]){
        //colour = "#4caf50";
        rank = "Excellent";
    }
    else if(score <= mean){
        //colour = "orange";
        rank = 'Below Average'
    }else{
        //colour = "#cddc39";
        rank = "Above Average";
    }
    return rank;
}

function calculateResults(data: QuestionnaireData): {[key: string]: TraitData[]} {
    const answers = data.answers;
    //first element is (mean - std) dev and second element is (mean + std) dev for the lists for each trait
    //Agreeableness
    const meeknessAns = [(5 - answers[51]), (5 - answers[101])];
    const meekness = [48.39113778, 84.8635792];
    const meeknessScore = getScore(meeknessAns);
    const meeknessMap: TraitData = {
        'name': 'Meekness',
        //'description': 'The extent to which an individual is patient, long-suffering, forbearing and resigned.',
        'score': meeknessScore,
        'rank': getRank(meeknessScore, meekness, 66.62736),
        //'correlation': getCorolation(meeknessScore, meekness)
    };

    const proSocialOrientationAns = [answers[46], answers[76], answers[91]];
    const proSocialOrientation = [70.66969059, 91.97181884];
    const proSocialOrientationScore = getScore(proSocialOrientationAns);
    const proSocialOrientationMap: TraitData = {
        'name': 'Pro-Social Orientation',
        //'description': 'The extent to which an individual is friendly, kind-hearted, pleasant, considerate helpful and warm-hearted.',
        'score': proSocialOrientationScore,
        'rank': getRank(proSocialOrientationScore, proSocialOrientation, 81.32075472),
        //'correlation': getCorolation(proSocialOrientationScore, proSocialOrientation)
    };

    const nonAntagonisticOrientationAns = [(5 - answers[96]), (5 - answers[86]), (5 - answers[81]), (5 - answers[61]), (5 - answers[56])];
    const nonAntagonisticOrientation = [52.93517094, 78.15916868];
    const nonAntagonisticOrientationScore = getScore(nonAntagonisticOrientationAns);
    const nonAntagonisticOrientationMap: TraitData = {
        'name': 'Non-Antagonistic Orientation',
        //'description': 'The extent to which an individual is not grouchy, arrogant, irritable, hot-tempered, hostile and argumentative.',
        'score': nonAntagonisticOrientationScore,
        'rank': getRank(nonAntagonisticOrientationScore, nonAntagonisticOrientation, 65.54716981),
        //'correlation': getCorolation(nonAntagonisticOrientationScore, nonAntagonisticOrientation)
    };

    const agreeableness = [
        meeknessMap,
        proSocialOrientationMap,
        nonAntagonisticOrientationMap
    ];

    //Neuroticism
    const depressionAns = [(5 - answers[58]), (5 - answers[88])];
    const depression = [37.30100762, 69.10465276];
    const depressionScore = getScore(depressionAns);
    const depressionMap: TraitData = {
        'name': 'Depression',
        //'description': 'The extent to which an individual is lonely, fearful, anxious and depressed.',
        'score': depressionScore,
        'rank': getRank(depressionScore, depression, 53.20283019),
        //'correlation': getCorolation(depressionScore, depression)
    };

    const selfReproachAns = [answers[68], answers[83], answers[93], answers[98]];
    const selfReproach = [31.12773502, 57.31566121];
    const selfReproachScore = getScore(selfReproachAns);
    const selfReproachMap: TraitData = {
        'name': 'Self-Reproach',
        //'description': 'The extent to which an individual is sad, afraid, insecure, depressed and troubled.',
        'score': selfReproachScore,
        'rank': getRank(selfReproachScore, selfReproach, 44.22169811),
        //'correlation': getCorolation(selfReproachScore, selfReproach)
    };


    const negativeAffectAns = [answers[48], answers[53], answers[63], answers[78]];
    const negativeAffect = [43.18291668, 70.67085691];
    const negativeAffectScore = getScore(negativeAffectAns);
    const negativeAffectMap: TraitData = {
        'name': 'Negative Affect',
        //'description': 'The extent to which an individual is depressed, sad, worried and afraid and insecure.',
        'score': negativeAffectScore,
        'rank': getRank(negativeAffectScore, negativeAffect, 56.92688679),
        //'correlation': getCorolation(negativeAffectScore, negativeAffect)
    };

    const neuroticism = [
        depressionMap,
        selfReproachMap,
        negativeAffectMap
    ];

    //Openness to Experience
    const unconventionalityAns = [(5 - answers[60]), (5 - answers[70]), (5 - answers[75]), (5 - answers[90])];
    const unconventionality = [61.66077415, 86.17413151];
    const unconventionalityScore = getScore(unconventionalityAns);
    const unconventionalityMap: TraitData = {
        'name': 'Unconventionality',
        //'description': 'The extent to which an individual is conservative, traditional and unusual.',
        'score': unconventionalityScore,
        'rank': getRank(unconventionalityScore, unconventionality, 73.91745283),
        //'correlation': getCorolation(unconventionalityScore, unconventionality)
    };

    const intellectualInterestAns = [answers[50], answers[95], answers[100]];
    const intellectualInterest = [67.90721101, 91.75945566];
    const intellectualInterestScore = getScore(intellectualInterestAns);
    const intellectualInterestMap: TraitData = {
        'name': 'Intellectual Interest',
        //'description': 'The extent to which an individual is intellectual, philosophical, deep, intelligent and knowledgeable. ',
        'score': intellectualInterestScore,
        'rank': getRank(intellectualInterestScore, intellectualInterest, 79.83333333),
        //'correlation': getCorolation(intellectualInterestScore, intellectualInterest)
    };

    const aestheticInterestAns = [answers[55], (5 - answers[65]), answers[85]];
    const aestheticInterest = [50.81754448, 84.02522282];
    const aestheticInterestScore = getScore(aestheticInterestAns);
    const aestheticInterestMap: TraitData = {
        'name': 'Aesthetic Interest',
        //'description': 'The extent to which an individual is artistic, imaginative, tolerant and curious. ',
        'score': aestheticInterestScore,
        'rank': getRank(aestheticInterestScore, aestheticInterest, 67.42138365),
        //'correlation': getCorolation(aestheticInterestScore, aestheticInterest)
    };

    const opennessToExperience = [
        unconventionalityMap,
        intellectualInterestMap,
        aestheticInterestMap
    ];

    //Extraversion
    const activityAns = [answers[64], answers[74], answers[89], answers[94]];
    const activity = [62.71325958, 86.03674042];
    const activityScore = getScore(activityAns);
    const activityMap: TraitData = {
        'name': 'Activity',
        //'description': 'The extent to which an individual is energetic, active, exciting, lively, busy, powerful, awesome and influential.',
        'score': activityScore,
        'rank': getRank(activityScore, activity, 74.375),
        //'correlation': getCorolation(activityScore, activity)
    };

    const positiveAffectAns = [answers[49], answers[79], (5 - answers[84])];
    const positiveAffect = [66.13719617, 90.73072836];
    const positiveAffectScore = getScore(positiveAffectAns);
    const positiveAffectMap: TraitData = {
        'name': 'Positive Affect',
        //'description': 'The extent to which an individual is joyful, cheerful, laughing, positive, glad and lively.',
        'score': positiveAffectScore,
        'rank': getRank(positiveAffectScore, positiveAffect, 78.43396226),
        //'correlation': getCorolation(positiveAffectScore, positiveAffect)
    };


    const sociabilityAns = [answers[44], (5 - answers[54]), answers[59], (5 - answers[69])];
    const sociability = [51.59335628, 77.90192674];
    const sociabilityScore = getScore(sociabilityAns);
    const sociabilityMap: TraitData = {
        'name': 'Sociability',
        //'description': 'The extent to which an individual is active, gets along with others and is talkative.',
        'score': sociabilityScore,
        'rank': getRank(sociabilityScore, sociability, 64.74764151),
        //'correlation': getCorolation(sociabilityScore, sociability)
    };

    const extraversion = [
        activityMap,
        positiveAffectMap,
        sociabilityMap
    ];

    //Conscientiousness
    const orderlinessAns = [answers[52], (5 - answers[57]), answers[72], (5 - answers[87]), (5 - answers[97])];
    const orderliness = [62.99806928, 86.14608166];
    const orderlinessScore = getScore(orderlinessAns);
    const orderlinessMap: TraitData = {
        'name': 'Orderliness',
        //'description': 'The extent to which an individual is organized, efficient, neat, systematic and thorough.',
        'score': orderlinessScore,
        'rank': getRank(orderlinessScore, orderliness, 74.57207547),
        //'correlation': getCorolation(orderlinessScore, orderliness)
    };

    const goalStrivingAns = [answers[62], answers[67], answers[77], answers[82], answers[92], answers[102]];
    const goalStriving = [74.03254897, 94.19072147];
    const goalStrivingScore = getScore(goalStrivingAns);
    const goalStrivingMap: TraitData = {
        'name': 'Goal Striving',
        //'description': 'The extent to which an individual is dedicated, ambitious, persistent and productive.',
        'score': goalStrivingScore,
        'rank': getRank(goalStrivingScore, goalStriving, 84.11163522),
        //'correlation': getCorolation(goalStrivingScore, goalStriving)
    };

    const conscientiousness = [
        orderlinessMap,
        goalStrivingMap
    ];

    //Goal Orientation
    const goalOrientationAns = [answers[11], answers[16], answers[21], answers[26], answers[31]];
    const goalOrientation = [68.70549519, 91.87941047];
    const goalOrientationScore = getScore(goalOrientationAns);
    const goalOrientationMap: TraitData = {
        'name': 'Goal Orientation',
        //'description': 'The extent to which the individual interprets environmental variations in light of a wide variety of personal, social, and organizational goals.',
        'score': goalOrientationScore,
        'rank': getRank(goalOrientationScore, goalOrientation, 80.29245283),
        //'correlation': getCorolation(goalOrientationScore, goalOrientation),
        //'individualProfile': [meeknessCorrelation,proSocialOrientationCorrelation,nonAntagonisticOrientationCorrelation,depressionCorrelation,selfReproachCorrelation,negativeAffectCorrelation,unconventionalityCorrelation,intellectualInterestCorrelation,aestheticInterestCorrelation,activityCorrelation,positiveAffectCorrelation,sociabilityCorrelation,orderlinessCorrelation,goalStrivingCorrelation],
        //'benchmark': [1,1,-1,-1,1,1,-1,1,1,1,1,-1,1,1]
    };

    //Monitoring
    const monitoringAns = [answers[10], answers[15], answers[20], answers[25], answers[30], answers[34]];
    const monitoring = [68.73081686, 89.48930892];
    const monitoringScore = getScore(monitoringAns);
    const monitoringMap: TraitData = {
        'name': 'Monitoring',
        //'description': 'Seeking and using feedback to re-evaluate goal orientation, metacognitive knowledge, metacognitive experience, and metacognitive choice for the purpose of ‘managing’ a changing environment.',
        'score': monitoringScore,
        'rank': getRank(monitoringScore, monitoring, 79.11006289),
        //'correlation': getCorolation(monitoringScore, monitoring),
        //'individualProfile': [meeknessCorrelation,proSocialOrientationCorrelation,nonAntagonisticOrientationCorrelation,depressionCorrelation,selfReproachCorrelation,negativeAffectCorrelation,unconventionalityCorrelation,intellectualInterestCorrelation,aestheticInterestCorrelation,activityCorrelation,positiveAffectCorrelation,sociabilityCorrelation,orderlinessCorrelation,goalStrivingCorrelation],
        //'benchmark': [1,1,-1,-1,-1,1,-1,1,1,1,1,-1,1,1]
    };

    //Metacognitive choice
    const choiceAns = [answers[9], answers[14], answers[19], answers[24], answers[29]];
    const choice = [66.89198914, 89.68536935];
    const choiceScore = getScore(choiceAns);
    const choiceMap: TraitData = {
        'name': 'Metacognitive Choice',
        //'description': 'The extent to which the individual engages in the active process of selecting from multiple decision frameworks the one that best interprets, plans, and implements a response for the purpose of ‘managing’ a changing environment.',
        'score': choiceScore,
        'rank': getRank(choiceScore, choice, 78.28867925),
        //'correlation': getCorolation(choiceScore, choice),
        //'individualProfile': [meeknessCorrelation,proSocialOrientationCorrelation,nonAntagonisticOrientationCorrelation,depressionCorrelation,selfReproachCorrelation,negativeAffectCorrelation,unconventionalityCorrelation,intellectualInterestCorrelation,aestheticInterestCorrelation,activityCorrelation,positiveAffectCorrelation,sociabilityCorrelation,orderlinessCorrelation,goalStrivingCorrelation],
        //'benchmark': [1,1,-1,-1,-1,1,-1,1,1,1,1,-1,-1,1]
    };

    //Metacognitive Experience
    const currentMEAns = [answers[12], answers[17], answers[22], answers[27], answers[32], answers[35]];
    const currentME = [73.91903054, 93.75392543];
    const currentMEScore = getScore(currentMEAns);
    const currentMEMap: TraitData = {
        'name': 'Current Metacognitive Experience',
        //'description': 'The extent to which the individual relies on current idiosyncratic experiences, emotions and information when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goals to ‘manage’ a changing environment.',
        'score': currentMEScore,
        'rank': getRank(currentMEScore, currentME, 83.83647799),
        //'correlation': getCorolation(currentMEScore, currentME),
        //'individualProfile': [meeknessCorrelation,proSocialOrientationCorrelation,nonAntagonisticOrientationCorrelation,depressionCorrelation,selfReproachCorrelation,negativeAffectCorrelation,unconventionalityCorrelation,intellectualInterestCorrelation,aestheticInterestCorrelation,activityCorrelation,positiveAffectCorrelation,sociabilityCorrelation,orderlinessCorrelation,goalStrivingCorrelation],
        //'benchmark': [1,1,-1,-1,-1,-1,-1,1,1,1,1,-1,1,1]
    };

    const priorMEAns = [answers[37], answers[39]];
    const priorME = [61.10823104, 94.0804482];
    const priorMEScore = getScore(priorMEAns);
    const priorMEMap: TraitData = {
        'name': 'Prior Metacognitive Experience',
        //'description': 'The extent to which the individual relies on previous idiosyncratic experiences, emotions, information and intuition when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goals to ‘manage’ a changing environment.',
        'score': priorMEScore,
        'rank': getRank(priorMEScore, priorME, 77.59433962),
        //'correlation': getCorolation(priorMEScore, priorME),
        //'individualProfile': [meeknessCorrelation,proSocialOrientationCorrelation,nonAntagonisticOrientationCorrelation,depressionCorrelation,selfReproachCorrelation,negativeAffectCorrelation,unconventionalityCorrelation,intellectualInterestCorrelation,aestheticInterestCorrelation,activityCorrelation,positiveAffectCorrelation,sociabilityCorrelation,orderlinessCorrelation,goalStrivingCorrelation],
        //'benchmark': [-1,1,-1,-1,-1,1,1,1,1,1,1,-1,-1,1]
    };

    //Metacognitive Knowledge
    const priorMKAns = [(5 - answers[23]), (5 - answers[28]), (5 - answers[36])];
    const priorMK = [30.61861011, 56.29962888];
    const priorMKScore = getScore(priorMKAns);
    const priorMKMap: TraitData = {
        'name': 'Prior Metacognitive Knowledge',
        //'description': 'The extent to which the individuals rely on what is previously known about oneself, other people and strategy when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goal to manage a changing environment.',
        'score': priorMKScore,
        'rank': getRank(priorMKScore, priorMK, 43.4591195),
        //'correlation': getCorolation(priorMKScore, priorMK),
        //'individualProfile': [meeknessCorrelation,proSocialOrientationCorrelation,nonAntagonisticOrientationCorrelation,depressionCorrelation,selfReproachCorrelation,negativeAffectCorrelation,unconventionalityCorrelation,intellectualInterestCorrelation,aestheticInterestCorrelation,activityCorrelation,positiveAffectCorrelation,sociabilityCorrelation,orderlinessCorrelation,goalStrivingCorrelation],
        //'benchmark': [1,-1,1,1,1,-1,1,-1,1,-1,-1,1,1,-1]
    };

    const currentMKAns = [answers[8], answers[13], answers[33], answers[38], answers[40], answers[41], answers[42]];
    const currentMK = [71.86905645, 91.21180608];
    const currentMKScore = getScore(currentMKAns);
    const currentMKMap: TraitData = {
        'name': 'Current Metacognitive Knowledge',
        //'description': 'The extent to which the individuals rely on what is currently known about oneself, other people and strategy when engaging in the process of generating multiple decision frameworks focused on interpreting, planning and implementing goal to manage a changing environment.',
        'score': currentMKScore,
        'rank': getRank(currentMKScore, currentMK, 81.54043127),
        //'correlation': getCorolation(currentMKScore, currentMK),
        //'individualProfile': [meeknessCorrelation,proSocialOrientationCorrelation,nonAntagonisticOrientationCorrelation,depressionCorrelation,selfReproachCorrelation,negativeAffectCorrelation,unconventionalityCorrelation,intellectualInterestCorrelation,aestheticInterestCorrelation,activityCorrelation,positiveAffectCorrelation,sociabilityCorrelation,orderlinessCorrelation,goalStrivingCorrelation],
        //'benchmark': [1,1,-1,-1,-1,1,-1,1,1,1,1,-1,-1,1]
    };

    const adaptability = [
        goalOrientationMap,
        monitoringMap,
        choiceMap,
        currentMEMap,
        priorMEMap,
        priorMKMap,
        currentMKMap,
    ];

    const results = {
        ca: adaptability,
        agreeableness: agreeableness,
        neuroticism: neuroticism,
        openness: opennessToExperience,
        extraversion: extraversion,
        conscientiousness: conscientiousness,
    };
    console.log(results);
    return results;
}