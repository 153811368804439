import Screen from "../components/common/screen";
import '../styles/questionnaire.css'
import Question from "../components/questionnaire/question";
import QuestionnaireBottomBar from "../components/questionnaire/bottomBar";
import QuestionnaireTopBar from "../components/questionnaire/topBar";
import {useEffect, useState} from "react";
import {useUser} from "../models/user";
import LoadingIndicator from "../components/common/loading";

const QuestionnaireScreen = () => {
    const user = useUser()
    const [questionnaire, setQuestionnaire] = useState(user.lastQuestionnaire)
    const [showPageLoading, setPageLoading] = useState(true)
    const [showNavLoading, setNavLoading] = useState(false)

    const [answers, setAnswers] = useState(questionnaire.answers)
    const [page, setPage] = useState(questionnaire.page)
    const [questions, setQuestions] = useState(questionnaire.currentQuestions)
    const [invalidQuestions, setInvalidQuestions] = useState<number[]>([])

    const content = document.getElementsByClassName("content")[0]

    useEffect(() => {
        user.getQuestionnaires().then(() => {
            const q = user.lastQuestionnaire
            setQuestionnaire(q)
            setAnswers(q.answers)
            setPage(q.page)
            setPageLoading(false)
        })
    }, [])

    useEffect(() => {
        questionnaire.answers = answers
    }, [answers])

    useEffect(() => {
        setQuestions(questionnaire.currentQuestions)
        content.scroll({ top: 0, behavior: "smooth" })
    }, [page])

    const updateAnswer = (index: number, option: number) => {
        const ans = [...answers]
        ans[index] = option
        setAnswers(ans)
    }

    const next = async () => {
        setNavLoading(true)
        const invalid = questionnaire.validateCurrentPage()
        setInvalidQuestions(invalid)
        if (invalid.length === 0) {
            await questionnaire.next()
            setPage(questionnaire.page)
        }
        setNavLoading(false)
    }

    const previous = async () => {
        setNavLoading(true)
        await questionnaire.back()
        setInvalidQuestions([])
        setPage(questionnaire.page)
        setNavLoading(false)
    }

    return <Screen key="/assessment" title="Readiness Assessment">
        <h2>Readiness Assessment</h2>
        <br />
        {showPageLoading? <LoadingIndicator />:
            <div className="questionnaire-card">
                <QuestionnaireTopBar questionnaire={questionnaire} />
                { questions.map(
                    index => <Question
                        invalidQuestions={invalidQuestions}
                        answers={answers}
                        updateAnswer={updateAnswer}
                        key={`Question ${index}`}
                        index={index}
                    />) }
                <p style={{color: "red", textAlign: "center"}}>
                    {invalidQuestions.length !== 0 ? "Please answer all the questions": ""}
                </p>
                {showNavLoading ? <div style={{height: '25px'}}><LoadingIndicator/></div> :
                    <QuestionnaireBottomBar next={next} previous={previous}/>
                }
            </div>
        }
    </Screen>
}

export default QuestionnaireScreen