import AptTickAPI from "../services/api";
import User from "./user";
import {on} from "cluster";

export interface CourseData {
    courseID: string
    title: string
    description: string
    category: string
    pdfURL: string
    sections: Section[]
    price: number
    status: string // draft, published, unpublished, deleted
    dateCreated: Date
    dateModified: Date
}

export default class Course {
    constructor(public data: CourseData, private summary?: CoursePersonalSummary) {}

    static async getCourse(courseID: string): Promise<Course> {
        return await AptTickAPI.getCourse(courseID)
    }

    static async syncCourses(user: User) {
        const summaries = await AptTickAPI.getCoursePersonalSummaryList(user)
        for (const summary of summaries) {
            const course = await Course.getCourse(summary.courseID)
            summary.title = course.data.title
            summary.category = course.data.category
            summary.description = course.data.description
            await AptTickAPI.saveCoursePersonalSummary(user.data.userID, summary)
        }
    }

    static async syncBundles(user: User) {
        const bundles = await AptTickAPI.getBundleList(user)
        for (const personalBundle of bundles) {
            const bundle = await AptTickAPI.getBundle(personalBundle.bundleID)
            await AptTickAPI.savePersonalBundle(user, bundle)
        }
    }

    // TODO: update course summary
    // IS course summary needed???
    nextSection = (index: number, setIndex: (i: number)=>void) => {
        const showQuiz = (index%2 === 1)

        if (showQuiz) {
            const sectionIndex = Math.floor(index/2)
            // TODO: check if current page is complete
        }

        const maxIndex = (2*this.data.sections.length)-1
        if (index <= maxIndex) {
            setIndex(index+1)
        }

        // TODO: sync progress online
    }

    prevSection = (index: number, setIndex: (i: number)=>void) => {
        setIndex(index-1)
    }

    // TODO: implement personal course summary
    getSummary = async (online: boolean = true): Promise<CoursePersonalSummary> => {
        if (!this.summary) {
            // TODO: try to get progress from DB
            try {
                if (online) {
                    const user = User.Local(false)
                    const summary = await AptTickAPI.getCourseSummary(user.data, this)
                    this.summary = summary
                    await this.saveProgress()
                    return summary
                } else {
                    throw 'Summary needs to be generated'
                }
            } catch (e) {
                // if personal summary not found, create new personal summary
                const summary = {
                    courseID: this.data.courseID,
                    title: this.data.title,
                    description: this.data.description,
                    category: this.data.category,
                    isComplete: false,
                    sections: [],
                }
                this.summary = summary
                await this.saveProgress()
                return summary
            }
        }
        return this.summary!
    }

    saveProgress = async () => {
        // TODO: save personal summary to DB
    }
}

export interface Section {
    title: string
    description: string
    videoURL: string
    thumbnailURL: string
    quiz: MCQuestion[]
    firstSlide: number
    lastSlide: number
}

export interface MCQuestion {
    question: string
    options: string[]
    correctIndex: number
    selectedIndex?: number
}

export interface CoursePersonalSummary {
    courseID: string
    title: string
    description: string
    category: string
    isComplete: boolean
    sections: Array<{title: string, isComplete: boolean, quiz: MCQuestion[]}>
}

export interface CourseVersionHistory {
    userID: string
    email: string
    dateModified: Date
    data: CourseData
}

export interface ContentBundle {
    bundleID: string
    title: string
    description: string
    isSequential: boolean
    status: string
    content: string[]
    dateCreated: Date
    dateModified: Date
}

export interface BundleVersionHistory {
    userID: string
    email: string
    dateModified: Date
    data: ContentBundle
}

export const emptyBundle: ContentBundle = {
    bundleID: 'none',
    title: '',
    description: '',
    status: 'draft',
    isSequential: false,
    content: [],
    dateCreated: new Date(),
    dateModified: new Date(),
}

const defaultQuestion: MCQuestion = {
    question: 'Is everybody naturally well suited towards entrepreneurship?',
    options: ["True", "False"],
    correctIndex: 1,
}

const defaultSection: Section = {
    title: 'Introduction',
    description: 'This is the introductory section for this particular course.',
    videoURL: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
    thumbnailURL: '',
    quiz: [
        {...defaultQuestion, question: 'Q1'},
        {...defaultQuestion, question: 'Q2'},
        {...defaultQuestion, question: 'Q3'},
    ],
    firstSlide: 0,
    lastSlide: 5,
}

// const defaultCourseData: CourseData = {
//     courseID: 'none',
//     title: 'Entrepreneurship Basics',
//     description: 'An introductory course to the basics of entrepreneurship.',
//     category: 'general',
//     pdfURL: 'none',
//     price: 0,
//     sections: [
//         {...defaultSection, title: 'Section 1', lastSlide: 5},
//         {...defaultSection, title: 'Section 2', lastSlide: 10},
//         {...defaultSection, title: 'Section 3', lastSlide: 15},
//     ],
//     dateCreated: new Date(),
//     dateModified: new Date(),
// }

// const fullCourseData: CourseData = {
//     courseID: 'none',
//     title: 'Full Test Short Course',
//     description: 'An introductory course to the basics of entrepreneurship.',
//     category: 'general',
//     pdfURL: 'none',
//     price: 0,
//     sections: [
//         {
//             title: 'Section 1',
//             description: 'This is the introductory section for this particular course.',
//             videoURL: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
//             quiz: [
//                 {
//                     question: 'Q1.a) This is an example of a question.',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//                 {
//                     question: 'Q1.b)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//                 {
//                     question: 'Q1.c)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//             ],
//             firstSlide: 1,
//             lastSlide: 5,
//         },
//         {
//             title: 'Section 2',
//             description: 'This is the introductory section for this particular course.',
//             videoURL: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
//             quiz: [
//                 {
//                     question: 'Q2.a)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//                 {
//                     question: 'Q2.b)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//                 {
//                     question: 'Q2.c)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//             ],
//             firstSlide: 6,
//             lastSlide: 10,
//         },
//         {
//             title: 'Section 3',
//             description: 'This is the introductory section for this particular course.',
//             videoURL: 'http://media.w3.org/2010/05/sintel/trailer.mp4',
//             quiz: [
//                 {
//                     question: 'Q3.a)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//                 {
//                     question: 'Q3.b)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//                 {
//                     question: 'Q3.c)',
//                     options: ["True", "False"],
//                     correctIndex: 1,
//                 },
//             ],
//             firstSlide: 11,
//             lastSlide: 15,
//         },
//     ],
//     dateCreated: new Date(),
//     dateModified: new Date(),
// }

const goalOrientationData: CourseData = {
    courseID: 'demo',
    title: 'Goal Orientation',
    description: 'An introductory course to the basics of entrepreneurship.',
    category: 'Cognitive Adaptability',
    pdfURL: 'GOAL ORIENTATION 2022.pdf',
    price: 0,
    sections: [
        {
            title: 'Goal Orientation',
            description: 'This is the introductory section for this particular course.',
            videoURL: 'Goal orientation 2022.mp4',
            thumbnailURL: 'Goal_orientation.png',
            quiz: [
                {
                    question: 'Q1.a) This is an example of a question.',
                    options: ["True", "False"],
                    correctIndex: 1,
                },
                {
                    question: 'Q1.b)',
                    options: ["True", "False"],
                    correctIndex: 1,
                },
                {
                    question: 'Q1.c)',
                    options: ["True", "False"],
                    correctIndex: 1,
                },
            ],
            firstSlide: 1,
            lastSlide: 47,
        },
    ],
    status: 'demo',
    dateCreated: new Date(),
    dateModified: new Date(),
}

export const  emptyQuestion: MCQuestion = {
    question: '',
    correctIndex: 0,
    options: []
}

export const emptySection: Section = {
    title: '',
    description: '',
    firstSlide: 0,
    lastSlide: 0,
    videoURL: '',
    thumbnailURL: '',
    quiz: [{...emptyQuestion}],
}

export const emptyCourseData: CourseData = {
    courseID: 'none',
    title: '',
    description: '',
    category: '',
    price: 0,
    pdfURL: '',
    status: 'draft',
    dateCreated: new Date(),
    dateModified: new Date(),
    sections: [{...emptySection}],
}


export const demoCourse = new Course(goalOrientationData) // new Course(defaultCourseData)
