import User from "./user";
import AptTickAPI from "../services/api";

const QUESTIONS = [
    'Gender',
    'What is your age',
    'Race',
    'What is the highest level of education you are in possession of?',
    'For how long have you run your business?',
    'In which sector does the main focus of your business lie?',
    'Province',

    'I think of several ways to solve a problem and choose the best one',
    'I ask myself if I have considered all the options when solving a problem.',
    'I periodically review to help me understand important relationships.',
    'I often define goals for myself',
    'I think about what I really need to accomplish before I begin a task',
    'I challenge my own assumptions about a task before I begin',
    'I ask myself if there was an easier way to do things after I finish a task.',
    'I stop and go back over information that is not clear.',
    'I understand how accomplishment of a task relates to my goals.',
    'I use different strategies depending on the situation.',
    'I think about how others may react to my actions.',
    'I ask myself if I have considered all the options after I solve a problem.',
    'I am aware of what strategies I use when engaged in a given task.',
    'I set specific goals before I begin a task.',
    'I organize my time to best accomplish my goals.',
    'I find myself automatically employing strategies that have worked in the past.',
    'I re-evaluate my assumptions when I get confused.',
    'I find myself pausing regularly to check my comprehension of the problem or situation at hand.',
    'I ask myself how well I’ve accomplished my goals once I’ve finished',
    'I am good at organizing information.',
    'I perform best when I already have knowledge of the task.',
    'I ask myself if I have learned as much as I could have when I finished the task.',
    'I ask myself questions about how well I am doing while I am performing a novel task.',
    'When performing a task, I frequently assess my progress against my objectives.',
    'I know what kind of information is most important to consider when faced with a problem.',
    'I create my own examples to make information more meaningful.',
    'I stop and reread when I get confused.',
    'I consciously focus my attention on important information.',
    'I try to use strategies that have worked in the past.',
    'My ‘gut’ tells me when a given strategy I use will be most effective.',
    'I ask myself questions about the task before I begin.',
    'I depend on my intuition to help me formulate strategies.',
    'I focus on the meaning and significance of new information.',
    'I try to translate new information into my own words.',
    'I try to break problems down into smaller temp.',
    'I am not a warrior.',
    'I like to have a lot of people around me.',
    'I enjoy concentrating on a fantasy or daydream and exploring all its possibilities, letting it grow and develop.',
    'I try to be courteous to everyone I meet.',
    'I keep my belongings neat and clean.',
    'At times I have felt bitter and resentful.',
    'I laugh easily.',
    'I think it’s interesting to learn and develop new hobbies.',
    'At times I bully or flatter people into doing what I want them to.',
    'I’m pretty good about pacing myself so as to get things done on time.',
    'When I’m under a great deal of stress, sometimes I feel like I’m going to pieces.',
    'I prefer jobs that let me work alone without being bothered by other people.',
    'I am intrigued by patterns I find in art and nature.',
    'Some people think I’m selfish and egotistical.',
    'I often come into situations without being fully prepared.',
    'I rarely feel lonely or blue.',
    'I really enjoy talking to people.',
    'I believe letting students hear controversial speakers can only confuse and mislead them.',
    'If someone starts a fight, I’m ready to fight back.',
    'I try to perform all the tasks assigned to me conscientiously.',
    'I often feel tense and jittery.',
    'I like to be where the action is.',
    'Poetry has little or no effect on me.',
    'I’m better than most people, and I know it.',
    'I have a clear set of goals and work toward them in an orderly fashion.',
    'Sometimes I feel completely worthless.',
    'I shy away from crowds of people.',
    'I would have difficulty just letting my mind wonder without control or guidance.',
    'When I’ve been insulted, I just try to forgive and forget.',
    'I waste a lot of time before settling down to work.',
    'I rarely feel fearful or anxious.',
    'I often feel as if I’m bursting with energy.',
    'I seldom notice the moods or feelings that different environments produce.',
    'I tend to assume the best about people.',
    'I work hard to accomplish my goals.',
    'I often get angry at the way people treat me.',
    'I am a cheerful, high-spirited person.',
    'I experience a wide range of emotions or feelings.',
    'Some people think of me as cold and calculating.',
    'When I make a commitment, I can always be counted on to follow through.',
    'Too often, when things go wrong, I get discouraged and feel like giving up.',
    'I don’t get much pleasure from chatting with people.',
    'Sometimes when I am reading poetry or looking at a work of art, I feel a chill or wave of excitement.',
    'I’m hard-headed and tough-minded in my attitudes.',
    'Sometimes I’m not as dependable or reliable as I should be.',
    'I am seldom sad or depressed.',
    'My life is fast-paced.',
    'I have little interest in speculating on the nature of the universe or the human condition.',
    'I generally try to be thoughtful and considerate.',
    'I am a productive person who always gets the job done.',
    'I often feel helpless and want someone else to solve my problems.',
    'I am a very active person.',
    'I have a lot of intellectual curiosity.',
    'If I don’t like people, I let them know it.',
    'I never seem to be able to get organised.',
    'At times I have been so ashamed I just wanted to hide.',
    'I would rather go my own way than be a leader of others.',
    'I often enjoy playing with theories or abstract ideas.',
    'If necessary, I am willing to manipulate people to get what I want.',
    'I strive for excellence in everything I do.',
];

const MAX_PER_PAGE = 10

export class Questionnaire {
    public page = 1

    constructor(public data: QuestionnaireData) {
        let index = 0
        // find the current page
        for (const ans of this.data.answers) {
            if (index > 8 && ans === 0) {
                this.page = Math.ceil((index - 7) / MAX_PER_PAGE)
                break
            }
            index++
        }
    }

    static question(num: number) {
        return QUESTIONS[num-1]
    }

    get answers() {
        return this.data.answers
    }

    set answers(newAns: number[]) {
        this.data.answers = newAns
    }

    get progress() {
        const total = this.answers.length - 7
        const incomplete = this.answers.filter(val => val === 0).length - 7
        const decimal = 1 - (incomplete/total)
        return Math.round(decimal*100)
    }

    get currentQuestions() {
        let questions = []
        const start = (this.page - 1) * MAX_PER_PAGE
        const end = this.page * MAX_PER_PAGE
        for (let i = start; i < end; i++) {
            if (i >= QUESTIONS.length-7) {
                break
            }
            questions.push(i + 8)
        }
        return questions
    }

    validateCurrentPage = () => {
        let invalidQuestions: number[] = []
        const start = (this.page - 1) * MAX_PER_PAGE
        const end = this.page * MAX_PER_PAGE
        for (let i = start; i < end; i++) {
            if (this.answers[i+8] === 0) {
                invalidQuestions.push(i+8)
            }
        }
        return invalidQuestions
    }

    next = async () => {
        if (this.page < (QUESTIONS.length-7)/MAX_PER_PAGE) {
            this.page += 1
            await AptTickAPI.updateQuestionnaire(this.data)
        } else {
            await this.submit()
        }
    }

    back = async () => {
        if (this.page > 1) {
            this.page -= 1
            await AptTickAPI.updateQuestionnaire(this.data)
        } else {
            window.alert("Already at first page")
        }
    }

    setAnswer = (question: number, option: number) => {
        if (this.answers) {
            console.log(this.answers)
            this.answers[question] = option
        }
    }

    submit = async () => {
        const user = User.Local()
        await AptTickAPI.submitQuestionnaire(user, this.data)
        window.location.replace('#/report')
    }
}

export interface QuestionnaireData {
    questionnaireID: string
    userID: string
    answers: number[]
    isComplete: boolean
    dateCreated: Date
    dateCompleted?: Date
}

export function newQuestionnaire(user: User): QuestionnaireData {
    return {
        questionnaireID: 'none',
        userID: user.data.userID,
        answers: new Array(103).fill(0),
        isComplete: false,
        dateCreated: new Date(),
    }
}