import Screen from "../components/common/screen";
import Course from "../models/course";
import CourseContentTabs from "../components/course/tabs";
import {FC, useEffect, useState} from "react";
import CoursePdfViewer from "../components/course/pdf";
import CourseVideoViewer from "../components/course/video";
import CourseInfoPage from "../components/course/info";
import CourseQuiz from "../components/course/quiz";
import {useHistory} from "react-router-dom";
import LoadingIndicator from "../components/common/loading";
import {useUser} from "../models/user";
import {AlertDialog} from "../components/common/alert";

// TODO: add access control and course progress
const CourseScreen = () => {
    const user = useUser()
    const history = useHistory()

    const [course, setCourse] = useState<Course | null>(null)
    const [pdfMode, setPdfMode] = useState(false)
    const [index, setIndex] = useState(0)
    const [showLoading, setLoading] = useState(true)
    const [isComplete, setComplete] = useState(false)

    useEffect(() => {
        const arr = history.location.pathname.split('/')
        const id = arr[arr.length-1]
        if (id) {
            if (user.isAdmin || user.hasCourseAccess(id)) {
                Course.getCourse(id).then(_course => {
                    setCourse(_course)
                    setLoading(false)
                    // TODO: use summary to update index
                }).catch(e => {
                    setLoading(false)
                })
            } else {
                setLoading(false)
            }
        } else {
            setLoading(false)
        }
    }, [])

    useEffect(() => {
        if (course) {
            if (index === 2*course.data.sections.length) {
                setComplete(true)
            }
        }
    }, [index])

    if (course) {
        return <Screen key='/course' title={course.data.title}>
            <CourseContentTabs pdfMode={pdfMode} setPdfMode={setPdfMode} />
            <br />
            <h2>{course.data.title}</h2>
            <br />
            {showLoading? <LoadingIndicator />:
                <CourseContent
                    pdfMode={pdfMode}
                    course={course}
                    index={index}
                    setIndex={setIndex}
                />
            }
            {
                isComplete? <AlertDialog
                    title='Course Completed!'
                    message="You have successfully completed this course."
                    onClose={() => history.push('/courses')}
                    buttonText="Continue"
                />: <></>
            }
        </Screen>
    } else {
        return <Screen key='/course' title='Not found'>
            <h2>Course</h2>
            <br />
            <div className="row" style={{justifyContent: "center", alignItems: "center", height: '100%'}}>
                <p style={{color: 'grey'}}>Not found</p>
            </div>
        </Screen>
    }
}

interface ContentProps {
    course: Course
    index: number
    setIndex: (i: number)=>void
    pdfMode: boolean
}

const CourseContent: FC<ContentProps> = ({ course, index, setIndex, pdfMode }) => {
    const showQuiz = (index%2 === 1)
    const sectionIndex = Math.floor(index/2)
    const section = course.data.sections[sectionIndex]

    const next = () => {
        course.nextSection(index, setIndex)
    }

    const prev = () => {
        course.prevSection(index, setIndex)
    }

    if (index === 2*course.data.sections.length) {
        return <div className="row" style={{flexDirection: 'column', justifyContent: "center", alignItems: "center", height: '100%'}}>
            <h2>Course Completed!</h2>
            <i style={{fontSize: 'xx-large', color: '#4caf50'}} className='material-icons'>verified</i>
        </div>
    }

    if (index < 0) {
        return <CourseInfoPage onClick={() => setIndex(0)} course={course.data} />
    }
    if (showQuiz) {
        return <CourseQuiz course={course} sectionIndex={sectionIndex} next={next} prev={prev} />
    }
    if (pdfMode) {
        return <CoursePdfViewer course={course} prev={prev} next={next} section={section} />
    } else {
        return <CourseVideoViewer course={course.data} section={section} next={next} prev={prev} />
    }
}

export default CourseScreen