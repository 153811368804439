import {CourseData, demoCourse} from "../../models/course";
import {FC} from "react";
import {dateToDMY} from "../../services/utils";

interface InfoProps {
    onClick: ()=>void
    course: CourseData
}

const CourseInfoPage: FC<InfoProps> = ({ onClick, course }) => {
    const enrolled = true
    const numSections = course.sections.length

    const icons: Record<string, string> = {
        'Entrepreneurship': 'work',
        "Cognitive Adaptability": 'model_training',
        "Broad Personality Traits": 'face',
        "Narrow Personality Traits": 'emoji_symbols',
    }

    return <div className='course-card'>
        <br />
        <div className='course-category'>
            <i className='material-icons'>{icons[course.category]}</i>
            <small>{course.category}</small>
        </div>
        <br />
        <p style={{color: 'grey'}}>Updated {dateToDMY(course.dateModified)}</p>
        <h2>{course.title}</h2>
        <hr />
        <p style={{color: 'grey'}}>{course.description}</p>
        <br />
        {/*<p>{numSections} Section{numSections===1?"":"s"}</p>*/}
        <br />
        {/* TODO: update onClick to handle enroll and begin */}
        <button className='course-button course-info-action' onClick={onClick}>{enrolled?"Begin": "Enroll Now"}</button>
    </div>
}

export default CourseInfoPage