import {getDocument, GlobalWorkerOptions, PDFDocumentProxy, PDFPageProxy} from 'pdfjs-dist/legacy/build/pdf'
import {FC, useEffect, useState} from "react";
import Course, {Section} from "../../models/course";
import AptTickAPI from "../../services/api";
import LoadingIndicator from "../common/loading";
import alert from "../common/alert";

interface PdfViewerProps {
    course: Course
    section: Section
    next: ()=>void
    prev: ()=>void
}

// TODO: cache the pdf
const CoursePdfViewer: FC<PdfViewerProps> = ({ course, section, prev, next }) => {
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(0)
    const [max, setMax] = useState(0)
    const [doc, setDoc] = useState<PDFDocumentProxy|undefined>()

    useEffect(() => {
        GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js' // 'pdfjs-dist/legacy/build/pdf.worker'
        loadPDF(course).then(_doc =>  {
            setDoc(_doc)
            setPage(section.firstSlide)
            setMax(_doc.numPages)
            // window.addEventListener('resize', listener);
        })
        return () => {
            // window.removeEventListener('resize', listener);
        }
    },[])

    useEffect(() => {
        GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js' // 'pdfjs-dist/legacy/build/pdf.worker'
        if (doc) {
            setLoading(true)
            renderPdf(doc as PDFDocumentProxy, page, setLoading).then(() => {
                setLoading(false)
                // setMax(doc.numPages)
            })
        }
    },[page])

    const listener = () => {
        if (doc) {
            setLoading(true)
            renderPdf(doc as PDFDocumentProxy, page, setLoading).then(() => {
                setLoading(false)
            })
        } else {
            window.alert(doc)
        }
    }

    const prevPage = () => {
        if (page > section.firstSlide) {
            setPage(page - 1)
        } else {
            prev()
        }
    }

    const nextPage = () => {
        if (page < section.lastSlide) {
            setPage(page + 1)
        } else {
            next()
        }
    }

    return <div className='course-card'>
        <h3>{section.title}</h3>
        <p>{section.description}</p>
        <br />
        <div style={{flex:'1'}}>
            {loading? <LoadingIndicator />: <></>}
            <canvas id="pdf-canvas" />
        </div>
        {loading? <></>: <div className="course-nav-row" style={{width: '100%', marginTop: '25px', alignItems: 'center'}}>
            <button className="course-button" onClick={prevPage}>
                <i className='material-icons'>chevron_left</i>
                <p>Back</p>
            </button>
            <p>{page} of {max}</p>
            <button className="course-button" onClick={nextPage}>
                <p>Next</p>
                <i className='material-icons'>chevron_right</i>
            </button>
        </div>}
    </div>
}

async function loadPDF(course: Course) {
    // TODO: update
    const pdfFile = await AptTickAPI.getFileURL(`Courses/${course.data.courseID}/${course.data.pdfURL}`);
    // const pdfFile = new Uint8Array(await AptTickAPI.getFileBytes('monitoring.pdf'))
    // const pdfFile = await (await AptTickAPI.getFileBlob('monitoring.pdf')).text()
    // return await getDocument({data: pdfFile}).promise
    return await getDocument(pdfFile).promise
}

async function renderPdf(doc: PDFDocumentProxy, pageNum: number, setLoading: (load: boolean)=>void) {
    setLoading(true)
    const canvas = document.querySelector<HTMLCanvasElement>("#pdf-canvas")!!
    const cxt = canvas.getContext("2d")!!

    const page = await doc.getPage(pageNum)
    const viewport = getScaledViewport(page)
    canvas.width = viewport.width
    canvas.height = viewport.height

    page.render({
        canvasContext: cxt,
        viewport,
    })
    setLoading(false)
}

/*function getScaledViewport(page: PDFPageProxy) {
    const {availWidth: aWidth, availHeight: aHeight} = window.screen
    let viewport = page.getViewport({scale: 1})
    const {width: vWidth, height: vHeight} = viewport

    console.log({
        screen: {
            width: aWidth,
            height: aHeight,
        },
        viewport: {
            width: vWidth,
            height: vHeight,
        }
    })

    let wScale = 1, hScale = 1
    if (aWidth > vWidth) {
        wScale = (aWidth-400) / vWidth
    } else {
        wScale = vWidth / aWidth
    }
    if (aHeight > vHeight) {
        hScale = aHeight / vHeight
    } else {
        hScale = vHeight / aHeight
    }

    if (wScale < hScale) {
        viewport = page.getViewport({ scale: wScale})
    } else {
        viewport = page.getViewport({ scale: hScale })
    }

    // TODO: Temporary, just for testing
    viewport = page.getViewport({ scale: 0.9 })
    // TODO: Temporary, just for testing

    return viewport
}*/

function getScaledViewport(page: PDFPageProxy) {
    let viewport = page.getViewport({scale: 1})
    const {width: vWidth} = viewport

    let scale = 1
    if (window.innerWidth > 900) {
        const width =  window.innerWidth - 510
        scale = width/vWidth
    } else if (window.innerWidth > 600) {
        const width =  window.innerWidth - 140
        scale = width/vWidth
    } else {
        const width =  window.innerWidth - 80
        scale = width/vWidth
    }

    viewport = page.getViewport({ scale })

    return viewport
}

function getFullScreenViewport(page: PDFPageProxy) {
    let viewport = page.getViewport({scale: 1})
    const {height: vHeight} = viewport

    const scale = window.innerHeight/vHeight
    console.log(scale)

    viewport = page.getViewport({ scale })

    return viewport
}

export default CoursePdfViewer