import {UploadTask, StorageError} from "firebase/storage";
import {FC, useEffect, useState} from "react";
import CustomDialog from "../common/alert";

interface DialogProps {
    uploads: UploadTask[]
    onComplete: ()=>void
    onError: (e: StorageError)=>void
}

const UploadProgressDialog: FC<DialogProps> = ({ uploads, onComplete, onError }) => {
    // const [numCompleted, setCompleted] = useState(0)
    const [progressList, setProgressList] = useState(uploads.map(() => 0))

    // useEffect(()=> {
    //     if (numCompleted === uploads.length) {
    //         onComplete()
    //     }
    // }, [numCompleted])

    // const handleItemCompletion = () => {
    //     setCompleted(numCompleted+1)
    // }

    useEffect(() => {
        // console.log(progressList)
        if (progressList.reduce((total, current) => total+current) === progressList.length) {
            onComplete()
        }
    }, [progressList])

    const onProgress = (index: number, progress: number) => {
        setProgressList((old) => {
            const list = [...old]
            list[index] = progress
            return [...list]
        })
    }

    return <CustomDialog>
        <h2>Uploading files</h2>
        <br />
        {uploads
            .map((upload, i) => <FileUploadProgressIndicator
                key={i}
                upload={upload}
                onProgress={(val) => onProgress(i, val)}
                onError={onError}
            />)
        }
    </CustomDialog>
}

interface IndicatorProps {
    upload: UploadTask
    onProgress: (progress: number)=>void
    onError: (e: StorageError)=>void
}

const FileUploadProgressIndicator: FC<IndicatorProps> = ({ upload, onProgress, onError }) => {
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        upload.on('state_changed',
            (snapshot) => {
                const prog = snapshot.bytesTransferred / snapshot.totalBytes
                setProgress(Math.round(prog*100));
                onProgress(prog)
            },
            (error) => {
                onError(error)
            },
            () => {
                // console.log(upload.snapshot.metadata.name, "Done")
                onProgress(1)
            }
        );
    }, [])

    return <div style={{borderTop: '1px solid #ccc', padding: '5px'}}>
        <p>{upload.snapshot.ref.name}</p>
        <div className='row'>
            <div style={{flex: '1', height: '10px', borderRadius: '5px', overflow: 'hidden', margin: '5px', backgroundColor: '#ddd', minWidth: '200px'}}>
                <div style={{height: '10px', width: `${progress}%`, backgroundColor: 'var(--secondary)'}} />
            </div>
            <p>{progress}%</p>
        </div>
    </div>
}

export default UploadProgressDialog