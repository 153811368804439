import Screen from "../components/common/screen";
import HomeCard from "../components/home/homeCard";
import {useUser} from "../models/user";

const HomeScreen = () => {
    // TODO: update access and user data on page refresh

    const user = useUser()

    const handleClick = (route: string) => {
        window.location.hash = route
    }

    return <Screen key="/" title="Home">
        <h2>Home</h2>
        <div className="home-grid">
            <HomeCard
                onClick={handleClick}
                enabled={user.hasQuestionnaireAccess}
                href="#/assessment"
                icon="list_alt"
                title="Questionnaire"
                description="Take the entrepreneurial readiness assessment to test your readiness for entrepreneurship"
                hint={user.hasReportAccess?"Questionnaire completed (100%)":''}
            />
            <HomeCard
                onClick={handleClick}
                enabled={user.hasReportAccess}
                href="#/report"
                icon="poll"
                title="Report"
                description="View your report with your scores compared to our benchmark"
                hint={user.hasQuestionnaireAccess?'Please complete the questionnaire':''}
            />
            {user.hasCourseAccess() ?
                <HomeCard
                    onClick={handleClick}
                    href="#/courses"
                    icon="school"
                    title="Courses"
                    description="Take a short course to better prepare for your entrepreneurship journey"
                />: <></>
            }
            <HomeCard
                onClick={handleClick}
                href="#/groups"
                icon="people"
                title="Groups"
                description="View your existing groups or request to join a new group."
            />
            {user.isAdmin?
                <HomeCard
                    onClick={handleClick}
                    href="#/admin"
                    icon="build"
                    title="Admin Panel"
                    description="Access the admin panel to manage users, courses etc. System administrators only."
                />: <></>
            }
        </div>
    </Screen>
}

export default HomeScreen