import {FC, useEffect} from "react";
import Appbar from "./Appbar";
import Sidebar from "./sidebar";

interface Props {
    title?: string,
}

// A common template to be used for creating a new page
const Screen: FC<Props> = ({ title = "AptTick", children }) => {
    // Set the page title
    useEffect(() => {
        if (!title.includes("AptTick")) {
            document.title = `${title} | AptTick`
        } else {
            document.title = `AptTick`
        }
    }, [])

    // Return the UI to be rendered
    return <div>
        <Appbar title="AptTick" />
        <div className="screen-row">
            <Sidebar />
            <div className="content">{ children }</div>
        </div>
    </div>
}

export default Screen