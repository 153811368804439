import CustomDialog from "../common/alert";
import {ContentBundle, CourseData, demoCourse} from "../../models/course";
import {FC, useEffect, useState} from "react";
import AdminAPI from "../../services/admin";
import {CourseAccess} from "../../models/access";

interface SelectorProps {
    initialState: string[]
    onClose: (access: string[])=>void
    includeBundles: boolean
}

const CourseSelector: FC<SelectorProps> = ({ onClose, initialState, includeBundles }) => {
    const [courses, setCourses] = useState<CourseData[]>([])
    const [bundles, setBundles] = useState<ContentBundle[]>([])
    const [selection, setSelection] = useState<string[]>(initialState)
    const [showLoading, setLoading] = useState(true)

    useEffect(() => {
        AdminAPI.getCourseList().then(async _courses => {
            setCourses(_courses)
            if (includeBundles) {
                const _bundles = await AdminAPI.getBundleList()
                setBundles(_bundles)
            }
            setLoading(false)
        })
    }, [])

    const selectCourse = (index: number) => {
        if (isCourseSelected(index)) {
            const selectionIndex = selection.findIndex((access) => access.includes(courses[index].courseID) && access.includes('course'))
            if (selectionIndex !== -1) {
                const newSelection = [...selection]
                newSelection.splice(selectionIndex, 1)
                setSelection([...newSelection])
            }
        } else {
            const newSelection = [...selection]
            newSelection.push(CourseAccess.newCourse(courses[index]).toString())
            setSelection([...newSelection])
        }
    }

    const isCourseSelected = (index: number): boolean => {
        // TODO: handle bundles and courses
        const selectionIndex = selection.findIndex((access) => access.includes(courses[index].courseID) && access.includes('course'))
        return (selectionIndex !== -1)
    }

    const selectBundle = (index: number) => {
        if (isBundleSelected(index)) {
            const selectionIndex = selection.findIndex((access) => access.includes(bundles[index].bundleID) && access.includes('bundle'))
            if (selectionIndex !== -1) {
                const newSelection = [...selection]
                newSelection.splice(selectionIndex, 1)
                setSelection([...newSelection])
            }
        } else {
            const newSelection = [...selection]
            newSelection.push(CourseAccess.newBundle(bundles[index]).toString())
            setSelection([...newSelection])
        }
    }

    const isBundleSelected = (index: number): boolean => {
        // TODO: handle bundles and courses
        const selectionIndex = selection.findIndex((access) => access.includes(bundles[index].bundleID) && access.includes('bundle'))
        return (selectionIndex !== -1)
    }

    return <CustomDialog isLoading={showLoading}>
        <div style={{maxHeight: '70%', overflowY: 'scroll'}}>
            {!includeBundles?<></>:
                <>
                    <h2>Bundles</h2>
                    <br />
                    {bundles.length===0? <p style={{textAlign: 'center', padding: '30px 0'}}>No bundles found</p>:
                        bundles.map((bundle, index) =>
                            <BundleItem
                                bundle={bundle}
                                selected={isBundleSelected(index)}
                                setSelected={() => selectBundle(index)}
                            />)
                    }
                    <br />
                </>
            }
            <h2>Courses</h2>
            <br />
            {courses.length === 0 ? <p style={{textAlign: 'center', padding: '30px 0'}}>No courses found</p>:
                courses.map(
                    (course, index) =>
                        <CourseItem
                            course={course}
                            selected={isCourseSelected(index)}
                            setSelected={() => selectCourse(index)}
                            key={`course-${course.courseID}`}
                        />)
            }
        </div>
        <br />
        <button className='course-button' onClick={() => onClose(selection)}>Done</button>
    </CustomDialog>
}

interface CourseProps {
    course: CourseData
    selected: boolean
    setSelected: ()=>void
}

const CourseItem: FC<CourseProps> = ({ course, selected, setSelected}) => {
    return <label className='row' style={{borderTop: '1px solid #ccc', padding: '10px 20px', minWidth: '220px'}}>
        <input
            checked={selected}
            onChange={setSelected}
            name={`course-${course.courseID}`}
            type={"checkbox"}
        />
        <div style={{width: '10px'}} />
        <p>{course.title}</p>
    </label>
}

interface BundleProps {
    bundle: ContentBundle
    selected: boolean
    setSelected: ()=>void
}

const BundleItem: FC<BundleProps> = ({ bundle, selected, setSelected}) => {
    return <label className='row' style={{borderTop: '1px solid #ccc', padding: '10px 20px', minWidth: '220px'}}>
        <input
            checked={selected}
            onChange={setSelected}
            name={`bundle-${bundle.bundleID}`}
            type={"checkbox"}
        />
        <div style={{width: '10px'}} />
        <p>{bundle.title}</p>
    </label>
}

export default CourseSelector