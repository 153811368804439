import {FC} from "react";

interface Props {
    title: string,
    score: number,
    description: string,
    rank: string
}

const ReportCard: FC<Props> = ({ title, description, score, rank }) => {
    const col = getColour(rank)

    return <div className="report-card">
        <h3 className="report-card-title">{ title }</h3>
        <div className="report-card-score" style={{color: col}}>{ score }%</div>
        <p className="report-card-description">{ description }</p>
        <br />
        <div className="report-card-rank" style={{color: col, borderColor: col}}>{ rank }</div>
    </div>
}

function getColour(rank: string): string {
    switch(rank) {
        case "Needs Work":
            return "red"
        case "Below Average":
            return "orange"
        case "Above Average":
            return "#4caf50"
        case "Excellent":
            return "green"
        default:
            return "var(--secondary)"
    }
}

export default ReportCard