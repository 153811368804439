import {FC, useEffect, useState} from "react";
import Course, {CoursePersonalSummary, MCQuestion, Section} from "../../models/course";
import MCQuestionContent from "./question";
import LoadingIndicator from "../common/loading";
import AptTickAPI from "../../services/api";
import {useUser} from "../../models/user";

interface QuizProps {
    // summary: CoursePersonalSummary
    // section: Section
    course: Course
    sectionIndex: number
    next: ()=>void
    prev: ()=>void
}

const CourseQuiz: FC<QuizProps> = ({ next, prev, course, sectionIndex }) => {
    const section = course.data.sections[sectionIndex]

    const user = useUser()
    const [isLoading, setLoading] = useState(false)
    const [displayType, setDisplayType] = useState('preview')
    const [qNum, setQNum] = useState(0)
    const [question, setQuestion] = useState(section.quiz[qNum])

    const handleContinue = async () => {
        if (displayType === 'preview') {
            setDisplayType('questions')
        } else if (displayType === 'questions') {
            const index = qNum + 1
            if (index < section.quiz.length) {
                setQNum(index)
                setQuestion(section.quiz[index])
            } else {
                // Save progress
                setLoading(true)
                const summary = await course.getSummary()
                if (summary.sections.length === sectionIndex) {
                    summary.sections.push({
                        title: section.title,
                        isComplete: true,
                        quiz: section.quiz
                    })
                }
                if (summary.sections.length === course.data.sections.length) {
                    summary.isComplete = true
                }
                await AptTickAPI.saveCoursePersonalSummary(user.data.userID, summary)
                setDisplayType('summary')
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        let complete = true
        section.quiz.forEach((question) => {
            if (!question.selectedIndex && question.selectedIndex!==0) {
                complete = false
            }
        })
        if (complete) {
            setDisplayType('summary')
        }
    }, [])

    return <div className='course-card'>
        <p>{section.title}</p>
        <h2>Quiz</h2>
        {isLoading? <LoadingIndicator/>:
            <>
                {displayType === 'preview'? <QuizPreview section={section} onClick={handleContinue} />: <></>}
                {displayType === 'questions'? <MCQuestionContent key={`${qNum}) ${question.question}`} index={qNum} question={question} action={handleContinue} />: <></>}
                {displayType === 'summary'? <QuizSummary quiz={section.quiz} next={next} prev={prev} />: <></>}
            </>
        }
    </div>
}

interface PreviewProps {
    section: Section
    onClick: () => void
}

const QuizPreview: FC<PreviewProps> = ({ section, onClick }) => {
    return <>
        <p>Total questions: {section.quiz.length}</p>
        <hr />
        <br />
        <p>This quiz consists only of multiple choice questions. Once you continue after answering a question you cannot go back and change your answer. Each question is based on content from the current section of the short course. Click the start button below to begin the quiz.</p>
        <br />
        <button className="course-button course-info-action" onClick={onClick}>Start</button>
    </>
}

interface SummaryProps {
    quiz: MCQuestion[]
    next: ()=>void
    prev: ()=>void
}

const QuizSummary: FC<SummaryProps> = ({ quiz, next, prev }) => {
    return <>
        <p>Completed</p>
        <hr />
        <br />
        {quiz.map((question, i) => <SummaryItem key={i} question={question} />)}
        <br />
        <div className="course-nav-row">
            <button className="course-button" onClick={prev}>
                <i className='material-icons'>chevron_left</i>
                <p>Back</p>
            </button>
            <button className="course-button" onClick={next}>
                <p>Next</p>
                <i className='material-icons'>chevron_right</i>
            </button>
        </div>
    </>
}

interface SummaryItemProps {
    question: MCQuestion
}

const SummaryItem: FC<SummaryItemProps> = ({ question }) => {
    const correct = (question.selectedIndex === question.correctIndex)
    // TODO: style
    return <>
        <div className='row'>
            <i style={{color: correct? 'green': 'red'}} className='material-icons'>
                {correct? 'check_circle': 'cancel'}
            </i>
            <p>{question.question}</p>
        </div>
        <br />
    </>
}

export default CourseQuiz