import Screen from "../../components/common/screen";
import {useUser} from "../../models/user";
import {useHistory} from "react-router-dom";
import {useEffect, useState} from "react";
import {emptyGroupData, GroupData} from "../../models/group";
import AptTickAPI from "../../services/api";
import AdminAPI from "../../services/admin";
import LoadingIndicator from "../../components/common/loading";
import {dateToDMY, dateToYMD} from "../../services/utils";
import AdminGroupAssessmentContentItem from "../../components/adminGroupEditor/assessmentContent";
import AdminGroupCourseContentItem from "../../components/adminGroupEditor/courseContent";
import CourseSelector from "../../components/adminGroupEditor/courseSelector";
import {ConfirmationDialog} from "../../components/common/alert";
import {GroupAccess} from "../../models/access";

interface ConfirmProps {
    show: boolean
    title: string
    message: string
    onConfirm: ()=>void
}

// TODO: add validation
const AdminGroupEditorScreen = () => {
    const user = useUser()
    const history = useHistory()
    const [id, setID] = useState('new')
    const [group, setGroup] =  useState(emptyGroupData)
    const [isLoading, setLoading] = useState(true)
    const [showCourseSelector, setCourseSelector] = useState(false)
    const [confirmDialogDetails, setConfirmDialog] = useState<ConfirmProps>({
        show: false,
        title: 'Confirm',
        message: 'confirm dialog',
        onConfirm: () => '',
    })

    useEffect(() => {
        const arr = history.location.pathname.split('/')
        const groupID = arr[arr.length-1]
        setID(groupID)
        if (groupID !== 'new') {
            AptTickAPI.getGroupData(groupID).then(_group => {
                setGroup(_group)
                setLoading(false)
            })
        } else {
            setLoading(false)
        }
    }, [])

    const saveGroup = async () => {
        setLoading(true)
        if (group.totalQuestionnaires > 0) {
            const assessmentAccess = GroupAccess.newAssessment(group.groupID).toString()
            if (!(group.access.includes(assessmentAccess))) {
                group.access.push(assessmentAccess)
            }
        }
        if (id === 'new') {
            const newID = await AdminAPI.createGroup(user.data, group)
            setID(newID)
            history.replace(`/group_editor/${newID}`)
        } else {
            await AdminAPI.saveGroup(user.data, group)
        }
        setLoading(false)
    }

    const previewGroup = async () => {
        await saveGroup()
        history.push(`/group/${id}`)
    }

    const confirmEnable = async () => {
        setConfirmDialog({
            show: true,
            title: group.enabled? 'Deactivate Group': 'Activate Group',
            message: group.enabled? 'Are you sure you want to deactivate this group':
                'Are you sure you want to activate this group?',
            onConfirm: enableGroup,
        })
    }

    const enableGroup = async () => {
        setConfirmDialog({...confirmDialogDetails, show: false})
        const tempGroup: GroupData = {...group, enabled: !group.enabled}
        group.enabled = tempGroup.enabled
        await saveGroup()
        setGroup({...tempGroup})
    }

    const confirmDelete = async () => {
        setConfirmDialog({
            show: true,
            title: 'Delete Group',
            message: 'Are you sure you want to permanently delete this group?',
            onConfirm: deleteGroup,
        })
    }

    const deleteGroup = async () => {
        setConfirmDialog({...confirmDialogDetails, show: false})
        const tempGroup = {...group, enabled: false, error: 'deleted'}
        group.error = 'deleted'
        await saveGroup()
        setGroup({...tempGroup})
        history.goBack()
    }

    const setField = (field: string, value: any) => {
        const tempGroup = {...group} as Record<string, any>
        tempGroup[field] = value
        setGroup({...tempGroup as GroupData})
    }

    const handleCourseSelection = (newAccess: string[]) => {
        const accessList = [...newAccess]
        setField('access', [...accessList])
        setCourseSelector(false)
    }

    const removeAccessItem = (access: string) => {
        const newAccess = [...group.access]
        const index = newAccess.indexOf(access)
        if (index !== -1) {
            newAccess.splice(index, 1)
        }
        setField('access', [...newAccess])
    }

    return <Screen key='/group_editor' title='Edit Group'>
        <h2>{id==='new'?'Create':'Edit'} Group</h2>
        <p>Created on {dateToDMY(group.dateCreated)}</p>
        <p style={{color: group.enabled?'yellowgreen': 'red'}}>{group.enabled? 'Active': 'Inactive'}</p>
        <br />
        <div className='row'>
            <button onClick={saveGroup} className='course-button'>Save</button>
            <span style={{width: '10px'}} />
            <button onClick={previewGroup} className='course-button'>Preview</button>
            <span style={{width: '10px'}} />
            <button onClick={confirmEnable} className='course-button'>{group.enabled? 'Deactivate': 'Activate'}</button>
            <span style={{width: '10px'}} />
            <button onClick={confirmDelete} className='course-button'>Delete</button>
        </div>
        {isLoading? <LoadingIndicator/>:
            <div className="course-card course-editor-card">

                <h3>Details</h3>
                <br />
                <p>Title</p>
                <input
                    value={group.title}
                    onChange={(e) => setField('title', e.target.value)}
                    className='course-editor-field'
                    placeholder='Title'
                />
                <br />
                <p>Description</p>
                <textarea
                    value={group.description}
                    // TODO: handle this case better
                    onChange={(e) => setField('description', e.target.value)}
                    className='course-editor-field'
                    placeholder='Description'
                />
                <br />
                <div className='row'>
                    <p>Group Code</p>
                    <input
                        value={group.groupCode}
                        onChange={(e) => setField('groupCode', e.target.value)}
                        className='course-editor-field'
                    />
                </div>
                <br />
                <div className='row'>
                    <p>Max. Members</p>
                    <input
                        value={group.maxMembers}
                        onChange={(e) => setField('maxMembers', parseInt(e.target.value))}
                        className='course-editor-field'
                        inputMode={'numeric'}
                        type={"number"}
                    />
                </div>
                <br />
                <div className='row'>
                    <p>Termination Date</p>
                    <input
                        value={dateToYMD(group.terminationDate)}
                        onChange={(e) => setField('terminationDate', new Date(e.target.value))}
                        className='course-editor-field'
                        type={"date"}
                    />
                </div>
                <br />
                <p>Error Message</p>
                <select
                    className="course-editor-field"
                    value={group.error}
                    onChange={(e) => setField('error', e.target.value)}
                    placeholder='none'
                >
                    <option>none</option>
                    <option value='expired'>Account term has expired</option>
                    <option value='unpaid'>Payment outstanding</option>
                    <option value='tos'>Breach in terms of service (TOS)</option>
                </select>

                <br />
                <hr />
                <br />
                <div className='row'>
                    <h3 style={{flex: 1}}>Content</h3>
                    <button className='course-button' onClick={() => setCourseSelector(true)}>Edit</button>
                </div>
                <br />
                <AdminGroupAssessmentContentItem group={group} setField={setField} />
                {
                    group.access
                        .map(access => <AdminGroupCourseContentItem
                            onDelete={removeAccessItem}
                            accessStr={access}
                            key={access}
                        />)
                }
                <br />
            </div>
        }
        {showCourseSelector? <CourseSelector onClose={handleCourseSelection} initialState={group.access} includeBundles={true} />: <></>}
        {confirmDialogDetails.show ?
            <ConfirmationDialog
                title={confirmDialogDetails.title}
                message={confirmDialogDetails.message}
                onCancel={() => setConfirmDialog({...confirmDialogDetails, show: false})}
                onConfirm={confirmDialogDetails.onConfirm}
            />: <></>
        }
    </Screen>
}

export default AdminGroupEditorScreen