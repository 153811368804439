import {Questionnaire} from "../../models/questionnaire";
import {Key} from "react";

interface QuestionProps {
    key: Key
    answers: number[]
    invalidQuestions: number[]
    updateAnswer: (index: number, option: number) => void
    index: number
}

const Question = ({ index, answers, updateAnswer, invalidQuestions }: QuestionProps) => {
    const question = Questionnaire.question(index)
    const isInvalid = invalidQuestions.includes(index)

    return <div key={`Question ${index}`} style={isInvalid? {color: "red"}: {}}>
        <br />
        <p>{ `${index-7}) ${question}` }</p>
        <div className="questionnaire-options">
            { [1, 2, 3, 4].map(
                i => <QuestionOption
                    key={`${index}-${i}`}
                    answers={answers}
                    updateAnswer={updateAnswer}
                    index={index}
                    option={i}
                />) }
        </div>
        <hr style={{border: "1px solid #ccc", borderBottomWidth: "0"}} />
        <br />
    </div>
}

interface OptionProps {
    answers: number[]
    updateAnswer: (index: number, option: number) => void
    index: number
    option: number
}

const QuestionOption = ({ index, option, answers, updateAnswer }: OptionProps) => {
    const values = ["None", "Strongly Disagree", "Disagree", "Agree", "Strongly Agree"]

    return <label className="option-container">
        <input
            type="radio"
            value={option}
            name={`Question ${index}`}
            onChange={() => updateAnswer(index, option)}
            checked={answers[index] === option}
        />
        <span className="questionnaire-answer">{ values[option] }</span>
    </label>
}

export default Question